body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: local("ProximaNova-Regular"),
    url("./assets/fonts/Proxima-Nova-Font/ProximaNova-Regular.otf")
      format("truetype");
  font-weight: "normal";
}

@font-face {
  font-family: "Proxima-Nova-Bold";
  src: local("Proxima-Nova-Bold"),
    url("./assets/fonts/Proxima-Nova-Font/Proxima Nova Bold.otf")
      format("truetype");
  font-weight: "bold";
}
@font-face {
  font-family: "FontsFree-Net-Proxima-Nova-Sbold";
  src: local("FontsFree-Net-Proxima-Nova-Sbold"),
    url("./assets/fonts/Proxima-Nova-Font/FontsFree-Net-Proxima-Nova-Sbold.otf")
      format("truetype");
  font-weight: "semi-bold";
}
