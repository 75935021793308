.border-radius-10 {
  border-radius: 10px;
}

.select-input-style {
  background-color: #fff;
  border-color: #ccc;
  font-size: 16px;
  color: #656565;
  border-radius: 0.25rem;
  box-shadow: none;
  font-size: 16px;
  height: 50px !important;
  width: 100%;
  font-style: normal !important;
}

div#uncontrolled-tab-example-tabpane-menu1 .list-group .list-group-item {
  padding-top: 16px !important;
}

/* .dashboard-left-links a.user-item.active:nth-child(1) img {
  filter: brightness(5.5);
} */
.gradiant-theme-color {
  /* background-image: linear-gradient(to right, #00D0FF, #00E0FF ); */
  background-color: #46bcff !important;
}

.onSelectionBackground {
  background-color: #46bcff !important;
  /* background-image: linear-gradient(to right, #00D0FF, #00E0FF ) !important; */
  color: #ffff !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.backgroundColorGray {
  background-color: #f5f5f5 !important;
  border: none !important;
}

.code-container {
  background-color: #f5f5f5;
  border: 1px dashed green;
}

.divider-vetical {
  border-left: 1px solid lightgray;
}

/* .imgFix{
    margin-top: -3px;
} */
.crd-img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  object-position: center;
  /* padding-right: 10px; */
}

.social-container {
  display: flex;
  justify-content: center;
}

.social-container> :nth-child(1) {
  margin-right: 10px;
}

.social-container> :nth-child(n) {
  cursor: pointer;
}

.social-container> :nth-child(n):hover {
  opacity: 0.8 !important;
}

.google-btn {
  box-shadow: none !important;
  margin-top: 10px !important;
}

.google-btn> :nth-child(1) {
  display: none !important;
}

.facebook-btn {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  width: 60px !important;
}

.desc-quil {
  height: 40px !important;
  margin-top: -10px !important;
}

.desc-quil>.ql-toolbar.ql-snow {
  display: none !important;
  border: none !important;
}

.desc-quil>.ql-container.ql-snow {
  border: none !important;
}

.credit-card-style {
  height: 40px !important;
  margin: 5px !important;
  border-radius: 10px !important;
  padding: 10px 5px 0px 5px !important;
  display: block !important;
  border: 1px solid #fff !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.1) -0em 0 .4em;
  width: 400px !important;
}

.credit-card-container {
  width: 100%;
  min-height: 50px;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.card-no {
  font-weight: 700 !important;
}

.card-item {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #e3e3e3;
  margin-bottom: 5px;
  height: 50px;
  padding: 10px;
}

.radio-upper {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 1px solid #46bcff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5;
  cursor: pointer;
}

.radio-inner {
  width: 10px;
  height: 10px;
  border-radius: 30px;
  border: 1px solid #46bcff;
  background-color: #46bcff;
}

.card-item-container {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  width: 100%;
}

.InputContainer .InputElement {
  position: absolute;
  top: 2px;
}

.conf-modal-footer,
.conf-modal-footer1 {
  display: flex;
  align-items: center;
}

.conf-modal-footer {
  justify-content: flex-end;
}

.conf-modal-footer1 {
  justify-content: center;
}

.info-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
}

.billing-info-container {
  background: #eaf8ff;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
}

.payment-container {
  width: 35%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.payment-header {
  border: 1px solid #46bcff;
  background-color: #46bcff;
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  height: 60px;
}

.payment-cards-container {
  background-color: #ffff;
  height: 60vh;
  border: 1px solid #e3e3e3;
  padding-top: 5px;
  overflow-y: auto;
}

.payment-header> :nth-child(2):hover {
  color: #e3e3e3 !important;
}

.fa-eye-slash {
  position: absolute;
  top: 12px;
  right: 8px;
  cursor: pointer;
  transition: all 0.5s
}

.fa-eye {
  position: absolute;
  top: 12px;
  right: 8px;
  cursor: pointer;
  transition: all 0.5s
}

.d-none {
  display: none !important
}

@media (max-width: 361px) {
  .payment-container {
    width: 90% !important;
  }
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

@media (min-width: 390px) {
  .english span {
    font-size: 20px !important;
  }

  .spanish span {
    font-size: 20px !important;
  }

  .list-inline {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .button__image-wrapper img {
    width: 150px !important;
    height: 150px !important;
    border-radius: 50%;
  }
  .button__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
}



/* 12 Oct 2022 */

@media (max-width: 767px) {

  .profileContainer .login-box .tab-content .tab-pane .col-md-12.d-flex.justifyContentSB {
    display: block !important;
  }

  .profileContainer .login-box .tab-content .tab-pane .col-md-12.d-flex.justifyContentSB {
    display: block !important;
    height: 86px;
  }

  .profileContainer.modal-body {
    overflow-y: scroll !important;
    height: 786px;
    padding-top: 0px;
  }

  .container.myAccount-centainer {
    padding: 15px 10px;
  }

  .container.myAccount-centainer .my-account-style {
    padding: 0px;
    margin: 0px !important;
    padding-left: 35px;
    margin-bottom: 15px !important;
  }

  .my-account-style .ddsh-body .col-md-12 {
    padding: 12px 22px !important;
  }

  .my-account-style .ml-3.d-flex.justifyContentSB.pr-3.height30px {
    margin: 0px 0px 0px 26px !important;
  }

  .my-account-style .ddsh-body .col-md-12 {
    padding: 12px 22px;
    border-bottom: 1px solid #dddddd;
  }

  .my-account-style .ddsh-body:nth-last-child(1) .col-md-12 {
    padding: 12px 22px;
    border-bottom: 1px solid #dddddd;
  }

  .my-account-style .ddsh-body .col-md-12:nth-last-child(1) {
    border: none;
  }

  .my-account-style br {
    display: none !important;
  }

  .container.myAccount-centainer {
    overflow: hidden;
  }

  /* Side Menu */
  .header_absolute .bm-menu-wrap .bm-menu ul.bm-item {
    padding: 0px;
    margin: 0px !important;
    margin-bottom: 15px !important;
  }

  .header_absolute .bm-menu-wrap .bm-menu ul.bm-item li {
    list-style: none;
    padding: 13px 0px;
    font-weight: 400 !important;
  }

  .bm-menu {
    box-shadow: 113px 0px 160px 20px #00000038;
    touch-action: none;
  }

  .dashboard-right .product-sort {
    float: none;
  }

  .dashboard-right nav.nav.nav-tabs.customtab.mb-10.nav.nav-tabs {
    display: block;
    position: relative;
    top: 0px;
    margin: 0px !important;
  }

  .search-box-expand {
    width: 90% !important;
  }

  .shop-summary-item img.position-absolute {
    top: 25px !important;
    right: 6px !important;
  }

  .shop-summary-item .item-info-name-features-price {
    padding: 0px;
  }

  .shop-summary-item .item-info-name-features-price span,
  p {
    padding: 0px !important;
    margin: 0px !important;
  }

  .datalist {
    width: 100% !important;
  }

  .datalist p.crossTextSpecialService span {
    margin-left: 10px;
  }

  section#services {
    overflow: hidden;
    padding-top: 50px;
  }

  .container-fluid .row .bg-white.addclasshere {
    min-width: 100% !important;
    width: 100% !important;
  }

  article.shop-summary-item.datalist img.cursor-pointer.m-1 {
    display: block !important;
    width: 100% !important;
    object-fit: contain;
    margin-bottom: 20px !IMPORTANT;
  }

  .item-info-name-features-price p.col-sm-8.d-flex.py-0.m-0:nth-last-child(1) span {
    width: 50%;
    display: block;
    float: left;
  }

  .item-info-name-features-price p.col-sm-8.d-flex.py-0.m-0:nth-last-child(1) {
    display: block !important;
  }

  .item-info-name-features-price p.col-sm-8.d-flex.py-0.m-0:nth-last-child(1) span:nth-last-child(1) {
    width: 50% !important;
    text-align: right;
  }

  .SetTabs .nav-tabs {
    display: grid !important;
    grid-template-columns: 48% 48% !important;
    align-items: center;
  }

  .work-wrap .text-holder.text-center div {
    height: auto !important;
  }

  .work-wrap p {
    margin-bottom: 50px !important;
  }

  .ins-float-icon-box-content p:nth-child(1) {
    line-height: 26.5px !important;
    margin-bottom: 15px !important;
  }

  .dashboard-left-links {
    border-bottom: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
  }

  .intro_layer.topleftimage {
    z-index: 3 !important;
    left: -127px;
    position: relative;
    top: 21px;
  }

  .intro_layers {
    position: absolute;
    top: 175px;
    right: 0;
    left: 0;
    background-color: white;
    height: 325px !important;
    width: 325px !important;
    border-radius: 50%;
    border: 15px solid #eee;
    padding: 25px 25px !important;
    margin: auto;
  }

  .col-5.mr-sm-3.set-home-pad {
    padding: 10px;
  }
}

body.modal-open .fade.modal.show {
  z-index: 99999999 !important;
}

header.page_header.ds {
  z-index: 9999999;
}

.bm-burger-button {
  z-index: 99999999 !important;
}

.bm-menu-wrap.d-lg-none.d-xl-none {
  z-index: 999999999 !important;
}

form#registerForm {
  padding: 0px 30px;
}

article.shop-summary-item:hover {
  cursor: pointer;
  transform: scale(1.02);
}

article.shop-summary-item {
  transition: all 0.5s ease-in-out;
}

.richTextContainer.messageToProviderContainer {
  margin-top: -65px;
}

.richTextContainer.messageToProviderContainer .quill.richTextBox .ql-toolbar.ql-snow {
  border-radius: 10px 10px 0px 0px;
}

.msg-box-uploader {
  display: grid !important;
  grid-template-columns: 20% 58% 22%;
  width: 800px;
}

.msg-box-uploader input#raised-button-file {
  margin: 15px 0px 0px !important;
  width: 90% !important;
}

.onCardHover {
  transition: all 0.5s ease-in-out;
}

.dashboard-left-links {
  border-bottom: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
}

.dashboard-left-links hr {
  display: none;
}

.SetTabs nav.mb-3.nav.nav-tabs {
  align-items: center;
  text-align: center;
}

.container-fluid .row .bg-white.addclasshere {
  left: 0 !important;
  right: 0 !important;
  min-width: 400px !important;
  width: 721px !important;
  margin: auto !important;
}

/* 
div#uncontrolled-tab-example-tabpane-menu1 .list-group .list-group-item {
  padding: 0px 10px;
  margin-bottom: 15px;
} */

.addclasshere span.d-flex.justify-content-end.mt-1.mr-1.cursorPointer img {
  margin-right: 10px;
  margin-top: 10px !important;
  width: 12px !important;
}

div#uncontrolled-tab-example-tabpane-menu1 .row.mt-1 {
  display: grid;
  grid-template-columns: 18% 76% 6%;
}

div#uncontrolled-tab-example-tabpane-menu1 .row.mt-1 .col-sm-7 {
  width: 100% !important;
  min-width: 100%;
  padding: 0px;
}

div#uncontrolled-tab-example-tabpane-menu1 .row.mt-1 .col-sm-3 {
  width: 100% !important;
  padding: 0px;
  min-width: 100%;
}

div#uncontrolled-tab-example-tabpane-menu1 .row.mt-1 .col-sm-2 {
  width: 100%;
  padding: 0px;
  min-width: 100%;
}

.dashboard-right {
  padding-top: 20px;
}

.billing-info-container {
  margin-top: 28px !important;
  display: block;
}

.product-details button.btn.btn-success:nth-child(1) {
  margin-bottom: 45px;
}

a#uncontrolled-tab-example-tab-menu.a.nav-item.nav-link.active::after {
  border-top: 20px solid #46bcff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  content: "";
  display: inline-block;
  right: 0;
  position: absolute;
  bottom: -25px;
  left: 0;
  margin: auto;
  text-align: center;
  width: 20px;
}

.my-account-style .ddsh-body .col-md-12.info-style h6 {
  color: #60bff5 !important;
}

.my-account-style .ddsh-body .col-md-12.info-style h {
  color: #60bff5 !important;
  font-weight: 400;
}



.dashboard-left-links a.user-item.active:nth-child(2) img {
  filter: brightness(5.5);
}

.dashboard-left-links a.user-item.active:nth-child(4) img {
  filter: brightness(5.5);
}

.my-account-style br {
  display: none;
}

.my-account-style .ddsh-body {
  padding-top: 20px;
}

.my-account-style .ddsh-body .col-md-12 {
  margin-bottom: 15px;
}

/* css for the global toast container */
.react-toast-notifications__container {
  z-index: 100000000000 !important;
}

.theme-text-color {
  color: #46BCFF;
}

.dark-text-secondary-color {
  color: #8D8D8D;
}

.cursor-pointer-class {
  cursor: pointer;
}







@media (min-width:320px) {
  /* smartphones, iPhone, portrait 480x320 phones */

  .p-60 {
    padding: 10px !important;
  }

  .wizard .nav-tabs>li {
    width: 33% !important;
  }

  .connecting-line-chrome {
    width: 50% !important;
  }

  .tab-content {
    padding: 0px 0px !important;
  }

  .element-emphasis-weak {
    padding: 8px 8px 8px !important;
  }

  .service-provider-card .document-management-service-avatar {
    width: 70px !important;
    height: 70px !important;
    object-fit: contain !important;

  }

  .service-provider-card .fontSize16px {
    font-size: 13px !important;
  }
}

@media (min-width:481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width:641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width:961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}

@media (min-width:1025px) {

  /* big landscape tablets, laptops, and desktops */
  .p-60 {
    padding: 60px !important;
  }

  .wizard .nav-tabs>li {
    width: 25% !important;
  }

  .connecting-line-chrome {
    width: 46% !important;
  }

  .tab-content {
    padding: 40px 40px !important;
  }

  .element-emphasis-weak {
    padding: 16px 16px 16px !important;
  }

  .service-provider-card .document-management-service-avatar {
    width: 100px !important;
    height: 100px !important;
    object-fit: contain !important;

  }

  .service-provider-card .fontSize16px {
    font-size: 16px !important;
  }
}

@media (min-width:1281px) {

  /* hi-res laptops and desktops */


}


/* gap  */
.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: .5rem !important
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important
}

.gap-4 {
  gap: 3rem !important
}