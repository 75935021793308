/* .chat-Container{

} */
.chat-Container::-webkit-scrollbar {
  width: 10px;
  height: 2px;
  background: #ededed;
}

.Document-Container::-webkit-scrollbar {
  width: 10px;
  height: 1px;
  background: #ededed;
}

.Document-Container::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;
  height: 10px;

}

.chat-Container::-webkit-scrollbar-thumb {
  background-color: #46bcff;
  border-radius: 10px;

}

.chat-Container .quill .ql-toolbar {
  display: none !important;
  padding: "3px" !important;
  border-radius: 10px !important;



}

.richTextBox1 {
  background-color: white;
  border-radius: 10px;
  /* border: 1px solid black; */
  margin-bottom: 20px;
  margin-top: 40px;
  min-height: 200px !important;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10x !important;
}

.userMessageContainer .quill.richTextBox {
  min-height: auto !important;
}


.sent_msg .render-html-info-quill .ql-editor {
  border: #46bcff 1px solid !important;
  background: #ffff !important;
  color: black;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  border-radius: 10px !important;
  border-top-right-radius: 0 !important;
  padding: 0.25rem;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0;
  padding: 1rem !important;
}

.bubble-container {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  font-family: sans-serif;

  align-items: center;
}

.bubble-direction-reverse {
  flex-direction: row-reverse;
}

.bubble {
  background-color: #F2F2F2;
  border-radius: 1px;
  color: black;
  display: block;
  padding: 10px 18px;
  position: relative;
  vertical-align: top;
  word-wrap: break-word;
}

.bubble::before {
  background-color: #F2F2F2;
  content: "\00a0";
  display: block;
  height: 16px;
  position: absolute;
  top: 11px;
  transform: rotate(29deg) skew(-35deg);
  -moz-transform: rotate(29deg) skew(-35deg);
  -ms-transform: rotate(29deg) skew(-35deg);
  -o-transform: rotate(29deg) skew(-35deg);
  -webkit-transform: rotate(29deg) skew(-35deg);
  width: 20px;
}

.me {
  background-color: #ffff;
  margin-left: 18px;
  margin-right: 60px;
  border-color: #46bcff !important;
  border-radius: 10px;
}

.me::before {
  box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, .4);
  left: -9px;
  background-color: #ffff;
  border-color: #46bcff !important
}

.you {
  background-color: #ffff;
  margin-left: 0px;
  margin-right: 4px;
  border-color: #1a85c3;
  border-color: #46bcff !important;
  border-radius: 10px;
}

.ql-editor2 {
  min-height: 100px !important;
}

.you::before {
  box-shadow: 2px -2px 2px 0 rgba(178, 178, 178, .4);
  right: -9px;
  background-color: #ffff;
  border-color: #46bcff !important
}

.img-circle {
  border-radius: 42%;
  height: 42px;
  width: 42px;
}

.chats {
  position: relative;
  height: 100%;
}

.chat-list {
  height: calc(100% - 3rem);
  overflow-y: scroll;
  padding: 1rem;
  padding-bottom: 0.5rem;
}

.new-message {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #1a85c3;
  height: 3rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-message-input {
  width: 99%;
  height: 80%;
  font-size: 1.5rem;
}

.border-primary {
  border-color: #46bcff !important;
  border-top-color: #46bcff !important;
  border-right-color: #46bcff !important;
  border-bottom-color: #46bcff !important;
  border-left-color: #46bcff !important;
}

.border-Radius {
  border-radius: 10px !important
}

richTextContainer .richTextBox {
  background-color: white;
  border-radius: 10px;
  /* border: 1px solid black; */
  margin-bottom: 20px;
  margin-top: 40px;
  /* min-height: 200px !important; */
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10x !important;
}

.fileUpload {
  border-radius: 10x !important;
}

.messageToProviderContainer .ql-container {
  height: 100px !important;
}

.customtab .tab-content {
  padding: 0;

}

.contentPadding .tab-content {
  padding: 0;

}

/*new chat ui stylings */
.container {
  max-width: 1170px;
  margin: auto;
}

img {
  max-width: 100%;
}

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #c4c4c4;
}

.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}

.top_spac {
  margin: 20px 0 0;
}


.recent_heading {
  float: left;
  width: 40%;
}

.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}

.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}

.chat_ib h5 span {
  font-size: 13px;
  float: right;
}

.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 100%;
  height: 50%;
}


/* .received_withd_msg .render-html-info-quill .ql-editor {
  background: #ffff !important;
  color: black;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  border-radius: 10px !important;
  border-top-left-radius: 0 !important;
  padding: 0.25rem;
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0;
  padding: 1rem !important;
} */

/* .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;

  border-radius: 10px;
  border-width: 5px solid;
  border-color: #9c3c3c;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
} */

.time_date {
  color: #747474 !important;
  margin: 8px 0 0;
  font-size: 10px !important;
  font-weight: 400 !important;
}


.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: auto;
}
.fileAttachment h6 {
  font-size: 12px !important;
  font-weight: normal !important;
}
.sent_msg {
  float: right;
  width: 46%;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {
  border-top: 1px solid #c4c4c4;
  position: relative;
}

.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}

.messaging {
  padding: 0 0 50px 0;
}

.msg_history {
  height: 516px;
  overflow-y: auto;
}

/*notification ui*/
.notification-ui a:after {
  display: none;
}

.notification-ui_icon {
  position: relative;
}

.notification-ui_icon .unread-notification {
  display: inline-block;
  height: 7px;
  width: 7px;
  border-radius: 7px;
  background-color: #66BB6A;
  position: absolute;
  top: 7px;
  left: 12px;
}

@media (min-width: 900px) {
  .notification-ui_icon .unread-notification {
    left: 20px;
  }
}

.notification-ui_dd {
  padding: 0;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
  border: 0;
  max-width: 400px;
}

@media (min-width: 900px) {
  .notification-ui_dd {
    min-width: 400px;
    position: absolute;
    left: -192px;
    top: 70px;
  }
}

.notification-ui_dd:after {
  content: "";
  position: absolute;
  top: -30px;
  left: calc(50% - 7px);
  border-top: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  border-left: 15px solid transparent;
}

.notification-ui_dd .notification-ui_dd-header {
  border-bottom: 1px solid #ddd;
  padding: 15px;
}

.notification-ui_dd .notification-ui_dd-header h3 {
  margin-bottom: 0;
}

.notification-ui_dd .notification-ui_dd-content {
  max-height: 500px;
  overflow: auto;
}

.notification-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 25px;
  /* border-bottom: 1px solid #ddd; */
}

.notification-list--unread {
  position: relative;
}

.notification-list--unread:before {
  content: "";
  position: absolute;
  top: 0;
  left: -25px;
  height: calc(100% + 1px);
  border-left: 2px solid #29B6F6;
}

.notification-list .notification-list_img img {
  height: 48px;
  width: 48px;
  border-radius: 50px;
  margin-right: 20px;
}

.notification-list .notification-list_detail p {
  margin-bottom: 5px;
  line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
  height: 48px;
  width: 48px;
  border-radius: 5px;
  margin-left: 20px;
}