@charset "utf-8";

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

html {
	height: 100%;
	overflow: visible;
	position: relative;
	width: 100%;
}

.active>a {
	color: #2196F3 !important;
}

::-moz-selection {
	background-color: #46bcff;
	color: #fff;
}

::selection {
	background-color: #46bcff;
	color: #fff;
}

::-moz-selection {
	background-color: #46bcff;
	color: #fff;
}

body {
	background-color: #fff;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 30px;
	overflow: visible;
	overflow-x: hidden;
	overflow-y: scroll;
	position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	line-height: 30px;
	margin-bottom: 15px;
	margin-top: 5px;
}

h1 {
	font-size: 56px;
	line-height: 50px;
}

h2 {
	font-size: 48px;
}

h3 {
	font-size: 42px;
}

h4 {
	font-size: 30px;
}

h5 {
	font-size: 21px;
}

h6 {
	font-size: 16px;
	font-weight: 400;
}

@media (max-width: 991px) {
	h6 {
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.8rem;
	}

	h2 {
		font-size: 1.6rem;
	}

	h3 {
		font-size: 1.4rem;
	}

	h4 {
		font-size: 1.3rem;
	}

	h5 {
		font-size: 1.2rem;
	}

	h6 {
		font-size: 1.1rem;
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
	margin-bottom: 0;
}

p {
	margin-bottom: 10px;
}

ul:last-child,
ol:last-child,
p:last-child {
	margin-bottom: 0;
}

p:last-child:after {
	clear: both;
	content: "";
	display: block;
}

img,
figure {
	height: auto;
	max-width: 100%;
}

figcaption {
	font-size: 0.95em;
	line-height: 1.4;
	padding: 10px 0 7px;
}

figcaption p {
	margin-bottom: 0;
}

b,
strong {
	font-weight: 600;
}

label {
	color: #323232;
}

a {
	color: #323232;
	text-decoration: none;
	-webkit-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
}

a:hover {
	color: #46bcff;
	text-decoration: none;
	-webkit-transition: all 0.15s linear 0s;
	transition: all 0.15s linear 0s;
}

blockquote {
	border-left: 4px solid #46bcff;
	color: #1f2732;
	font-size: 20px;
	font-style: italic;
	margin: 30px 0 53px;
	padding: .4em 0 .4em 1.8em;
}

blockquote .media {
	margin: 15px 0;
}

blockquote .media img {
	border-radius: 50%;
	max-width: 60px;
}

blockquote h3,
blockquote h4,
blockquote .text-small {
	font-style: normal;
}

input:focus,
button:focus,
select:focus,
textarea:focus,
a:focus {
	outline: medium none;
	text-decoration: none;
}

a>img {
	-webkit-transition: all 0.15s ease-in-out 0s;
	transition: all 0.15s ease-in-out 0s;
}

a:hover>img {
	opacity: 0.8;
}

a.btn:active,
a.button:active,
button:active,
input[type="submit"]:active {
	position: relative;
	top: 1px !important;
}

hr {
	border-color: #e1e1e1;
	margin-bottom: 30px;
	margin-left: 0;
	margin-right: 0;
	margin-top: 30px;
}

iframe {
	border: none;
	max-width: 100%;
}

table {
	margin: 10px 0;
	max-width: 100%;
	width: 100%;
}

table td,
table th {
	line-height: 1.6;
	padding: 13px 8px;
	vertical-align: top;
}

table th {
	color: #323232;
	font-weight: normal;
	vertical-align: middle;
}

canvas {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

pre {
	background-color: rgba(150, 150, 150, 0.05);
	line-height: 1.5;
	padding: 0.5em 1em;
}

.big {
	font-size: 20px;
	line-height: 30px;
	margin: 1.4em 0 1.3em;
}

.big em {
	display: inline-block;
	margin: 1em 0 0.5em;
}

.media h3 {
	text-transform: uppercase;
}

.media .dropcap {
	margin-right: 0;
}

.small-text {
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
	line-height: 1.6em;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.small-text.extra-letter-spacing {
		letter-spacing: 1em;
	}
}


dt {
	font-weight: 700;
}

dd {
	margin: 0 1.5em 1.5em;
}



/*
** Bootstrap Overrides
*/
/* bootstrap breadcrumbs*/
.breadcrumb {
	background-color: transparent;
	border-radius: 0;
	color: inherit;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

.breadcrumb>li {
	display: inline-block;
	float: none;
	padding: 0;
}

.text-center .breadcrumb {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

@media (min-width: 992px) {
	.text-lg-right .breadcrumb {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
}

.breadcrumb>.active {
	color: inherit;
	opacity: 0.9;
}

.breadcrumb-item+.breadcrumb-item::before {
	color: inherit;
	padding: 0 11px;
}

.layout-2 .breadcrumb {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: end;
}

@media (max-width: 992px) {
	.layout-2 .breadcrumb {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

.layout-2 .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
	color: #1f2732;
	content: "\f054";
	font-family: FontAwesome;
	font-size: 7px;
	padding: 0 7px;
	position: relative;
	top: -1px;
}

.layout-2 .widget_search form {
	float: right;
	margin: 0;
}

@media (max-width: 992px) {
	.layout-2 .widget_search form {
		float: none;
		margin: 15px auto 0;
	}
}

.layout-2 .widget_search form .form-control {
	text-align: left;
}

.circlelogo {
	width: 80px !important;
	margin-top: 8px !important;

}

.btn-success {
	color: #fff;
	background-color: #46bcff;
	border-color: #46bcff !important;
}

/* forms */
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
textarea,
select,
.form-control {
	background-color: #fff;
	border-color: #ccc;
	font-size: 16px;
	color: #656565;
	border-radius: .25rem;
	box-shadow: none;
	font-size: 16px;
	height: 50px;
	width: 100%;
	font-style: normal !important;
}

input[disabled] {
	cursor: not-allowed;
	opacity: .4;
}

.form-check-label input[type="checkbox"],
.form-check-label input[type="radio"] {
	margin-top: 15px;
}

input[type="submit"],
button {
	cursor: pointer;
}

input[type="submit"],
button,
.prev-step,
.next-step {
	background: #46bcff;
	border: 4px solid #46bcff;
	border-radius: 50px;
	color: #fff;
	cursor: pointer;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 1.6em;
	padding: 0px 40px;
	text-transform: uppercase;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	height: 45px;
}


.ds input[type="submit"]:not([class]),
.cs input[type="submist"]:not([class]),
.ds button:not([class]),
.cs button:not([class]) {
	border-color: #fff;
	color: #fff;
}

textarea,
textarea.form-control {
	height: auto;
	padding-top: 20px;
}

.form-group {
	position: relative;
}

[class*="col-"]>.form-group {
	margin-bottom: 25px;
}

[class*='col-']>.form-group+.form-group {
	margin-top: 0px;
	margin-bottom: 0;
}

.has-placeholder label {
	display: none;
}

.form-check-label {
	margin-bottom: 0;
	display: inline-block;
	min-width: 70px;
	line-height: 50px;
}

/* select styling */
select[multiple] {
	height: auto;
}

select option,
.form-control option {
	background-color: #fff;
	color: #323232;
}

.form-control-unstyled {
	background-color: transparent;
	border: none;
}

.form-control,
.form-control:focus {
	box-shadow: none;
}

.form-control.invalid {
	box-shadow: 0 0 0 2px #46bcff;
}

.form-control:focus {
	background-color: #f4f4f5;
	border-color: #dedfe1;
	color: #7f7f7f;
}

.select-outer .form-control:focus {
	background-color: transparent;
	border-color: #dedfe1;
	color: #fff;
}



label {
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0em;
	line-height: 1.6em;
	/* text-transform: none; */
}

.checkbox label,
.radio label {
	font-weight: inherit;
}

.checkbox label[for],
.radio label[for] {
	font-weight: inherit;
}

.checkbox label[for]+input[type=checkbox],
.checkbox label[for]+input[type=radio],
.radio label[for]+input[type=checkbox],
.radio label[for]+input[type=radio] {
	left: 20px;
}

input[type=checkbox],
input[type=radio] {
	margin-top: 8px;
}

.has-error .form-control {
	border-color: #46bcff;
}

.has-error label,
.has-error .help-block {
	color: #46bcff;
}

/*styled checkbox */
.checkbox input[type="checkbox"] {
	display: none;
}

.checkbox label+input[type="checkbox"],
.checkbox label>input[type="checkbox"] {
	display: inline-block;
}

input[type="checkbox"]+label {
	padding-left: 0;
}

input[type="checkbox"]+label>a {
	color: #323232;
}

input[type="checkbox"]+label:before {
	border: 1px solid #e1e1e1;
	content: "";
	display: inline-block;
	font-family: "FontAwesome";
	font-size: 15px;
	height: 16px;
	line-height: 14px;
	margin: 1px 10px 4px 0;
	padding-right: 0.1em;
	text-align: center;
	vertical-align: middle;
	width: 16px;
}

input[type="checkbox"]:checked+label:before {
	color: #323232;
	content: "\f00c";
}

input[type="checkbox"]:disabled+label:before {
	background-color: #e1e1e1;
}

/*styled radio*/
.radio input[type="radio"] {
	display: none;
}

.radio label+input[type="radio"],
.radio label>input[type="radio"] {
	display: inline-block;
}

input[type="radio"]+label {
	padding-left: 0;
}

input[type="radio"]+label>a {
	color: #999;
}

input[type="radio"]+label:before {
	border: 2px solid #e1e1e1;
	border-radius: 50%;
	content: "";
	display: inline-block;
	height: 16px;
	margin-bottom: 2px;
	margin-right: 10px;
	vertical-align: middle;
	width: 16px;
}

input[type="radio"]:checked+label:before {
	background-clip: content-box;
	background-color: #46bcff;
	padding: 3px;
}

input[type="radio"]:disabled+label:before {
	background-color: #e1e1e1;
}



/* bootstrap tabs*/
.nav-tabs {
	border-bottom: none;
}

.nav-tabs .nav-link {
	background-color: #1f2732;
	border-radius: 30px 30px 0 0;
	color: #fff;
	margin-right: 4px;
}

@media (max-width: 767px) {
	.nav-tabs .nav-link {
		border-radius: 15px;
		margin-bottom: 10px;
		padding: 12px 10px 12px;
		text-align: center;
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.nav-tabs .nav-link {
		padding: 12px 8px;
	}
}

.nav-tabs .nav-link:hover {
	background: #46bcff;
	/* background-image: linear-gradient(to right, #00D0FF, #00E0FF ) !important; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	border: 5px solid;
	background: #46bcff;

	/* background-image: linear-gradient(to right, #00D0FF, #00E0FF ) !important; */
	color: #fff;
	border: 10px double #fff;
}

.mainlisting ul li a img {
	position: relative !important;
	left: 0 !important;
	top: 0 !important;
}

.nav-link {
	color: #323232;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
	line-height: 1.6em;
	padding: 15px 28px;
	text-transform: none;
}

.tab-content {
	background: #eaf8ff;
	border-radius: 10px;
	box-shadow: 0px 0px 5px #ccc;
}

.nav-link a {
	display: block;
}

.small-tabs .nav-link {
	padding: 10px 15px;
}

.tab-content {
	padding: 40px 40px;
}

/* bootstrap dropdown menu */
.dropdown-menu {
	background-color: #fff;
	border: none;
	border-radius: 0;
	border-top: 3px solid #46bcff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	font-size: inherit;
	line-height: 1.5;
	margin-top: 0;
	padding: 40px;
	z-index: 1001;
}

.dropdown-menu>a {
	display: block;
	white-space: nowrap;
}

.dropdown-menu>a>img {
	max-width: none;
}

.dropdown-menu>a+a {
	margin-top: 1.5em;
}

.dropdown .dropdown-menu label,
.dropdown .dropdown-menu h3,
.dropdown .dropdown-menu h4 {
	color: #323232;
}

.dropdown.show .dropdown-menu input {
	border: 1px solid #e1e1e1;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
	background-color: transparent;
	color: #323232;
}

.dropdwon-menu-title {
	padding: 10px 30px;
}

@media (min-width: 1300px) {
	.container {
		max-width: 1200px;
	}
}

/* bootstrap buttons */
.btn {
	border: 2px solid;
	border-radius: 50px;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 1px !important;
	letter-spacing: 0.2em;
	padding: 0px 20px !important;
	text-transform: uppercase;
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	height: 45px;
	line-height: 40px;
}

.btn.round,
.btn.rounded {
	line-height: 1;
	padding: 23px;
}


/* 
** toTop
*/
#toTop {
	background-color: #46bcff;
	/* background-image: linear-gradient(to right, #00D0FF, #00E0FF ); */
	border-radius: 50%;
	bottom: 10px;
	color: transparent;
	font-size: 0;
	height: 50px;
	opacity: 0.7;
	overflow: hidden;
	position: fixed;
	right: 10px;
	text-decoration: none;
	width: 50px;
	z-index: 9999;
}

#toTop:after {
	bottom: 0;
	color: #fff;
	content: "\f106";
	display: block;
	font-family: "FontAwesome";
	font-size: 16px;
	left: 0;
	line-height: 47px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

#toTopHover,
#toTop:hover {
	bottom: 3px;
	opacity: 1;
}

#toTop:hover:after {
	color: #fff;
}

#toTop:active,
#toTop:focus {
	outline: medium none;
}

.modal-open #toTop {
	visibility: hidden;
}



/*
** Vertical margins and padding for sections and columns
*/
/* Vertical padding for sections
.s - section
pt-* - padding top value
pb-* - padding bottom value
py-* - same value for top and for bottom padding
*/
.s-pt-0>[class*='container'] {
	padding-top: 0;
}

.s-pt-1>[class*='container'] {
	padding-top: 1px;
}

.s-pt-2>[class*='container'] {
	padding-top: 2px;
}

.s-pt-3>[class*='container'] {
	padding-top: 3px;
}

.s-pt-5>[class*='container'] {
	padding-top: 5px;
}

.s-pt-10>[class*='container'] {
	padding-top: 10px;
}

.s-pt-15>[class*='container'] {
	padding-top: 15px;
}

.s-pt-20>[class*='container'] {
	padding-top: 20px;
}

.s-pt-25>[class*='container'] {
	padding-top: 25px;
}

.s-pt-30>[class*='container'] {
	padding-top: 30px;
}

.s-pt-50>[class*='container'] {
	padding-top: 50px;
}

.s-pt-60>[class*='container'] {
	padding-top: 60px;
}

.s-pt-75>[class*='container'] {
	padding-top: 75px;
}

.s-pt-100>[class*='container'] {
	padding-top: 100px;
}

.s-pt-130>[class*='container'] {
	padding-top: 130px;
}

.s-pt-70>[class*='container'] {
	padding-top: 70px;
}

s-pt-lg-70>[class*='container'] {
	padding-top: 70px;
}

.s-pb-0>[class*='container'] {
	padding-bottom: 0;
}

.s-pb-1>[class*='container'] {
	padding-bottom: 1px;
}

.s-pb-2>[class*='container'] {
	padding-bottom: 2px;
}

.s-pb-3>[class*='container'] {
	padding-bottom: 3px;
}

.s-pb-5>[class*='container'] {
	padding-bottom: 5px;
}

.s-pb-10>[class*='container'] {
	padding-bottom: 10px;
}

.s-pb-15>[class*='container'] {
	padding-bottom: 15px;
}

.s-pb-20>[class*='container'] {
	padding-bottom: 20px;
}

.s-pb-25>[class*='container'] {
	padding-bottom: 25px;
}

.s-pb-30>[class*='container'] {
	padding-bottom: 30px;
}

.s-pb-50>[class*='container'] {
	padding-bottom: 50px;
}

.s-pb-60>[class*='container'] {
	padding-bottom: 60px;
}

.s-pb-75>[class*='container'] {
	padding-bottom: 75px;
}

.s-pb-100>[class*='container'] {
	padding-bottom: 100px;
}

.s-pb-130>[class*='container'] {
	padding-bottom: 130px;
}

.s-py-0>[class*='container'] {
	padding-bottom: 0;
	padding-top: 0;
}

.s-py-1>[class*='container'] {
	padding-bottom: 1px;
	padding-top: 1px;
}

.s-py-2>[class*='container'] {
	padding-bottom: 2px;
	padding-top: 2px;
}

.s-py-3>[class*='container'] {
	padding-bottom: 3px;
	padding-top: 3px;
}

.s-py-5>[class*='container'] {
	padding-bottom: 5px;
	padding-top: 5px;
}

.s-py-10>[class*='container'] {
	padding-bottom: 10px;
	padding-top: 10px;
}

.s-py-15>[class*='container'] {
	padding-bottom: 15px;
	padding-top: 15px;
}

.s-py-20>[class*='container'] {
	padding-bottom: 20px;
	padding-top: 20px;
}

.s-py-25>[class*='container'] {
	padding-bottom: 25px;
	padding-top: 25px;
}

.s-py-30>[class*='container'] {
	padding-bottom: 30px;
	padding-top: 30px;
}

.s-py-50>[class*='container'] {
	padding-bottom: 50px;
	padding-top: 50px;
}

.s-py-60>[class*='container'] {
	padding-bottom: 60px;
	padding-top: 60px;
}

.s-py-75>[class*='container'] {
	padding-bottom: 75px;
	padding-top: 75px;
}

.s-py-100>[class*='container'] {
	padding-bottom: 100px;
	padding-top: 100px;
}

.s-py-130>[class*='container'] {
	padding-bottom: 130px;
	padding-top: 130px;
}

@media (min-width: 576px) {
	.s-pt-sm-0>[class*='container'] {
		padding-top: 0;
	}

	.s-pt-sm-1>[class*='container'] {
		padding-top: 1px;
	}

	.s-pt-sm-2>[class*='container'] {
		padding-top: 2px;
	}

	.s-pt-sm-3>[class*='container'] {
		padding-top: 3px;
	}

	.s-pt-sm-5>[class*='container'] {
		padding-top: 5px;
	}

	.s-pt-sm-10>[class*='container'] {
		padding-top: 10px;
	}

	.s-pt-sm-15>[class*='container'] {
		padding-top: 15px;
	}

	.s-pt-sm-20>[class*='container'] {
		padding-top: 20px;
	}

	.s-pt-sm-25>[class*='container'] {
		padding-top: 25px;
	}

	.s-pt-sm-30>[class*='container'] {
		padding-top: 30px;
	}

	.s-pt-sm-50>[class*='container'] {
		padding-top: 50px;
	}

	.s-pt-sm-60>[class*='container'] {
		padding-top: 60px;
	}

	.s-pt-sm-75>[class*='container'] {
		padding-top: 75px;
	}

	.s-pt-sm-100>[class*='container'] {
		padding-top: 100px;
	}

	.s-pt-sm-130>[class*='container'] {
		padding-top: 130px;
	}

	.s-pb-sm-0>[class*='container'] {
		padding-bottom: 0;
	}

	.s-pb-sm-1>[class*='container'] {
		padding-bottom: 1px;
	}

	.s-pb-sm-2>[class*='container'] {
		padding-bottom: 2px;
	}

	.s-pb-sm-3>[class*='container'] {
		padding-bottom: 3px;
	}

	.s-pb-sm-5>[class*='container'] {
		padding-bottom: 5px;
	}

	.s-pb-sm-10>[class*='container'] {
		padding-bottom: 10px;
	}

	.s-pb-sm-15>[class*='container'] {
		padding-bottom: 15px;
	}

	.s-pb-sm-20>[class*='container'] {
		padding-bottom: 20px;
	}

	.s-pb-sm-25>[class*='container'] {
		padding-bottom: 25px;
	}

	.s-pb-sm-30>[class*='container'] {
		padding-bottom: 30px;
	}

	.s-pb-sm-50>[class*='container'] {
		padding-bottom: 50px;
	}

	.s-pb-sm-60>[class*='container'] {
		padding-bottom: 60px;
	}

	.s-pb-sm-75>[class*='container'] {
		padding-bottom: 75px;
	}

	.s-pb-sm-100>[class*='container'] {
		padding-bottom: 100px;
	}

	.s-pb-sm-130>[class*='container'] {
		padding-bottom: 130px;
	}

	.s-py-sm-0>[class*='container'] {
		padding-bottom: 0;
		padding-top: 0;
	}

	.s-py-sm-1>[class*='container'] {
		padding-bottom: 1px;
		padding-top: 1px;
	}

	.s-py-sm-2>[class*='container'] {
		padding-bottom: 2px;
		padding-top: 2px;
	}

	.s-py-sm-3>[class*='container'] {
		padding-bottom: 3px;
		padding-top: 3px;
	}

	.s-py-sm-5>[class*='container'] {
		padding-bottom: 5px;
		padding-top: 5px;
	}

	.s-py-sm-10>[class*='container'] {
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.s-py-sm-15>[class*='container'] {
		padding-bottom: 15px;
		padding-top: 15px;
	}

	.s-py-sm-20>[class*='container'] {
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.s-py-sm-25>[class*='container'] {
		padding-bottom: 25px;
		padding-top: 25px;
	}

	.s-py-sm-30>[class*='container'] {
		padding-bottom: 30px;
		padding-top: 30px;
	}

	.s-py-sm-50>[class*='container'] {
		padding-bottom: 50px;
		padding-top: 50px;
	}

	.s-py-sm-60>[class*='container'] {
		padding-bottom: 60px;
		padding-top: 60px;
	}

	.s-py-sm-75>[class*='container'] {
		padding-bottom: 75px;
		padding-top: 75px;
	}

	.s-py-sm-100>[class*='container'] {
		padding-bottom: 100px;
		padding-top: 100px;
	}

	.s-py-sm-130>[class*='container'] {
		padding-bottom: 130px;
		padding-top: 130px;
	}
}

@media (min-width: 768px) {
	.s-pt-md-0>[class*='container'] {
		padding-top: 0;
	}

	.s-pt-md-1>[class*='container'] {
		padding-top: 1px;
	}

	.s-pt-md-2>[class*='container'] {
		padding-top: 2px;
	}

	.s-pt-md-3>[class*='container'] {
		padding-top: 3px;
	}

	.s-pt-md-5>[class*='container'] {
		padding-top: 5px;
	}

	.s-pt-md-10>[class*='container'] {
		padding-top: 10px;
	}

	.s-pt-md-15>[class*='container'] {
		padding-top: 15px;
	}

	.s-pt-md-20>[class*='container'] {
		padding-top: 20px;
	}

	.s-pt-md-25>[class*='container'] {
		padding-top: 25px;
	}

	.s-pt-md-30>[class*='container'] {
		padding-top: 30px;
	}

	.s-pt-md-50>[class*='container'] {
		padding-top: 50px;
	}

	.s-pt-md-60>[class*='container'] {
		padding-top: 60px;
	}

	.s-pt-md-75>[class*='container'] {
		padding-top: 75px;
	}

	.s-pt-md-100>[class*='container'] {
		padding-top: 100px;
	}

	.s-pt-md-130>[class*='container'] {
		padding-top: 130px;
	}

	.s-pb-md-0>[class*='container'] {
		padding-bottom: 0;
	}

	.s-pb-md-1>[class*='container'] {
		padding-bottom: 1px;
	}

	.s-pb-md-2>[class*='container'] {
		padding-bottom: 2px;
	}

	.s-pb-md-3>[class*='container'] {
		padding-bottom: 3px;
	}

	.s-pb-md-5>[class*='container'] {
		padding-bottom: 5px;
	}

	.s-pb-md-10>[class*='container'] {
		padding-bottom: 10px;
	}

	.s-pb-md-15>[class*='container'] {
		padding-bottom: 15px;
	}

	.s-pb-md-20>[class*='container'] {
		padding-bottom: 20px;
	}

	.s-pb-md-25>[class*='container'] {
		padding-bottom: 25px;
	}

	.s-pb-md-30>[class*='container'] {
		padding-bottom: 30px;
	}

	.s-pb-md-50>[class*='container'] {
		padding-bottom: 50px;
	}

	.s-pb-md-60>[class*='container'] {
		padding-bottom: 60px;
	}

	.s-pb-md-75>[class*='container'] {
		padding-bottom: 75px;
	}

	.s-pb-md-100>[class*='container'] {
		padding-bottom: 100px;
	}

	.s-pb-md-130>[class*='container'] {
		padding-bottom: 130px;
	}

	.s-py-md-0>[class*='container'] {
		padding-bottom: 0;
		padding-top: 0;
	}

	.s-py-md-1>[class*='container'] {
		padding-bottom: 1px;
		padding-top: 1px;
	}

	.s-py-md-2>[class*='container'] {
		padding-bottom: 2px;
		padding-top: 2px;
	}

	.s-py-md-3>[class*='container'] {
		padding-bottom: 3px;
		padding-top: 3px;
	}

	.s-py-md-5>[class*='container'] {
		padding-bottom: 5px;
		padding-top: 5px;
	}

	.s-py-md-10>[class*='container'] {
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.s-py-md-15>[class*='container'] {
		padding-bottom: 15px;
		padding-top: 15px;
	}

	.s-py-md-20>[class*='container'] {
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.s-py-md-25>[class*='container'] {
		padding-bottom: 25px;
		padding-top: 25px;
	}

	.s-py-md-30>[class*='container'] {
		padding-bottom: 30px;
		padding-top: 30px;
	}

	.s-py-md-50>[class*='container'] {
		padding-bottom: 50px;
		padding-top: 50px;
	}

	.s-py-md-60>[class*='container'] {
		padding-bottom: 60px;
		padding-top: 60px;
	}

	.s-py-md-75>[class*='container'] {
		padding-bottom: 75px;
		padding-top: 75px;
	}

	.s-py-md-100>[class*='container'] {
		padding-bottom: 100px;
		padding-top: 100px;
	}

	.s-py-md-130>[class*='container'] {
		padding-bottom: 130px;
		padding-top: 130px;
	}
}

@media (min-width: 992px) {
	.s-pt-lg-0>[class*='container'] {
		padding-top: 0;
	}

	.s-pt-lg-1>[class*='container'] {
		padding-top: 1px;
	}

	.s-pt-lg-2>[class*='container'] {
		padding-top: 2px;
	}

	.s-pt-lg-3>[class*='container'] {
		padding-top: 3px;
	}

	.s-pt-lg-5>[class*='container'] {
		padding-top: 5px;
	}

	.s-pt-lg-10>[class*='container'] {
		padding-top: 10px;
	}

	.s-pt-lg-15>[class*='container'] {
		padding-top: 15px;
	}

	.s-pt-lg-20>[class*='container'] {
		padding-top: 20px;
	}

	.s-pt-lg-25>[class*='container'] {
		padding-top: 25px;
	}

	.s-pt-lg-30>[class*='container'] {
		padding-top: 30px;
	}

	.s-pt-lg-50>[class*='container'] {
		padding-top: 50px;
	}

	.s-pt-lg-60>[class*='container'] {
		padding-top: 60px;
	}

	.s-pt-lg-75>[class*='container'] {
		padding-top: 75px;
	}

	.s-pt-lg-100>[class*='container'] {
		padding-top: 100px;
	}

	.s-pt-lg-130>[class*='container'] {
		padding-top: 130px;
	}

	.s-pb-lg-0>[class*='container'] {
		padding-bottom: 0;
	}

	.s-pb-lg-1>[class*='container'] {
		padding-bottom: 1px;
	}

	.s-pb-lg-2>[class*='container'] {
		padding-bottom: 2px;
	}

	.s-pb-lg-3>[class*='container'] {
		padding-bottom: 3px;
	}

	.s-pb-lg-5>[class*='container'] {
		padding-bottom: 5px;
	}

	.s-pb-lg-10>[class*='container'] {
		padding-bottom: 10px;
	}

	.s-pb-lg-15>[class*='container'] {
		padding-bottom: 15px;
	}

	.s-pb-lg-20>[class*='container'] {
		padding-bottom: 20px;
	}

	.s-pb-lg-25>[class*='container'] {
		padding-bottom: 25px;
	}

	.s-pb-lg-30>[class*='container'] {
		padding-bottom: 30px;
	}

	.s-pb-lg-50>[class*='container'] {
		padding-bottom: 50px;
	}

	.s-pb-lg-60>[class*='container'] {
		padding-bottom: 60px;
	}

	.s-pb-lg-75>[class*='container'] {
		padding-bottom: 75px;
	}

	.s-pb-lg-100>[class*='container'] {
		padding-bottom: 100px;
	}

	.s-pb-lg-130>[class*='container'] {
		padding-bottom: 130px;
	}

	.s-py-lg-0>[class*='container'] {
		padding-bottom: 0;
		padding-top: 0;
	}

	.s-py-lg-1>[class*='container'] {
		padding-bottom: 1px;
		padding-top: 1px;
	}

	.s-py-lg-2>[class*='container'] {
		padding-bottom: 2px;
		padding-top: 2px;
	}

	.s-py-lg-3>[class*='container'] {
		padding-bottom: 3px;
		padding-top: 3px;
	}

	.s-py-lg-5>[class*='container'] {
		padding-bottom: 5px;
		padding-top: 5px;
	}

	.s-py-lg-10>[class*='container'] {
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.s-py-lg-15>[class*='container'] {
		padding-bottom: 15px;
		padding-top: 15px;
	}

	.s-py-lg-20>[class*='container'] {
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.s-py-lg-25>[class*='container'] {
		padding-bottom: 25px;
		padding-top: 25px;
	}

	.s-py-lg-30>[class*='container'] {
		padding-bottom: 30px;
		padding-top: 30px;
	}

	.s-py-lg-50>[class*='container'] {
		padding-bottom: 50px;
		padding-top: 50px;
	}

	.s-py-lg-60>[class*='container'] {
		padding-bottom: 60px;
		padding-top: 60px;
	}

	.s-py-lg-75>[class*='container'] {
		padding-bottom: 75px;
		padding-top: 75px;
	}

	.s-py-lg-100>[class*='container'] {
		padding-bottom: 100px;
		padding-top: 100px;
	}

	.s-py-lg-130>[class*='container'] {
		padding-bottom: 130px;
		padding-top: 130px;
	}
}

@media (min-width: 1200px) {
	.s-pt-xl-0>[class*='container'] {
		padding-top: 0;
	}

	.s-pt-xl-1>[class*='container'] {
		padding-top: 1px;
	}

	.s-pt-xl-2>[class*='container'] {
		padding-top: 2px;
	}

	.s-pt-xl-3>[class*='container'] {
		padding-top: 3px;
	}

	.s-pt-xl-5>[class*='container'] {
		padding-top: 5px;
	}

	.s-pt-xl-10>[class*='container'] {
		padding-top: 10px;
	}

	.s-pt-xl-15>[class*='container'] {
		padding-top: 15px;
	}

	.s-pt-xl-20>[class*='container'] {
		padding-top: 20px;
	}

	.s-pt-xl-25>[class*='container'] {
		padding-top: 25px;
	}

	.s-pt-xl-30>[class*='container'] {
		padding-top: 30px;
	}

	.s-pt-xl-50>[class*='container'] {
		padding-top: 50px;
	}

	.s-pt-xl-60>[class*='container'] {
		padding-top: 60px;
	}

	.s-pt-xl-75>[class*='container'] {
		padding-top: 75px;
	}

	.s-pt-xl-100>[class*='container'] {
		padding-top: 100px;
	}

	.s-pt-xl-130>[class*='container'] {
		padding-top: 130px;
	}

	.s-pb-xl-0>[class*='container'] {
		padding-bottom: 0;
	}

	.s-pb-xl-1>[class*='container'] {
		padding-bottom: 1px;
	}

	.s-pb-xl-2>[class*='container'] {
		padding-bottom: 2px;
	}

	.s-pb-xl-3>[class*='container'] {
		padding-bottom: 3px;
	}

	.s-pb-xl-5>[class*='container'] {
		padding-bottom: 5px;
	}

	.s-pb-xl-10>[class*='container'] {
		padding-bottom: 10px;
	}

	.s-pb-xl-15>[class*='container'] {
		padding-bottom: 15px;
	}

	.s-pb-xl-20>[class*='container'] {
		padding-bottom: 20px;
	}

	.s-pb-xl-25>[class*='container'] {
		padding-bottom: 25px;
	}

	.s-pb-xl-30>[class*='container'] {
		padding-bottom: 30px;
	}

	.s-pb-xl-50>[class*='container'] {
		padding-bottom: 50px;
	}

	.s-pb-xl-60>[class*='container'] {
		padding-bottom: 60px;
	}

	.s-pb-xl-75>[class*='container'] {
		padding-bottom: 75px;
	}

	.s-pb-xl-100>[class*='container'] {
		padding-bottom: 100px;
	}

	.s-pb-xl-130>[class*='container'] {
		padding-bottom: 130px;
	}

	.s-py-xl-0>[class*='container'] {
		padding-bottom: 0;
		padding-top: 0;
	}

	.s-py-xl-1>[class*='container'] {
		padding-bottom: 1px;
		padding-top: 1px;
	}

	.s-py-xl-2>[class*='container'] {
		padding-bottom: 2px;
		padding-top: 2px;
	}

	.s-py-xl-3>[class*='container'] {
		padding-bottom: 3px;
		padding-top: 3px;
	}

	.s-py-xl-5>[class*='container'] {
		padding-bottom: 5px;
		padding-top: 5px;
	}

	.s-py-xl-10>[class*='container'] {
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.s-py-xl-15>[class*='container'] {
		padding-bottom: 15px;
		padding-top: 15px;
	}

	.s-py-xl-20>[class*='container'] {
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.s-py-xl-25>[class*='container'] {
		padding-bottom: 25px;
		padding-top: 25px;
	}

	.s-py-xl-30>[class*='container'] {
		padding-bottom: 30px;
		padding-top: 30px;
	}

	.s-py-xl-50>[class*='container'] {
		padding-bottom: 50px;
		padding-top: 50px;
	}

	.s-py-xl-60>[class*='container'] {
		padding-bottom: 60px;
		padding-top: 60px;
	}

	.s-py-xl-75>[class*='container'] {
		padding-bottom: 75px;
		padding-top: 75px;
	}

	.s-py-xl-100>[class*='container'] {
		padding-bottom: 100px;
		padding-top: 100px;
	}

	.s-py-xl-130>[class*='container'] {
		padding-bottom: 130px;
		padding-top: 130px;
	}
}

/* Vertical dividers - use them with Bootstrap responsive utilities - http://getbootstrap.com/docs/4.1/utilities/display/#hiding-elements */
[class*='divider-'] {
	clear: both;
	width: 100%;
}

[class*='divider-']:last-child {
	-webkit-box-ordinal-group: 10001;
	-ms-flex-order: 10000;
	order: 10000;
}

.divider-0 {
	margin-top: 0;
}

.divider-10 {
	margin-top: 10px;
}

.divider-20 {
	margin-top: 20px;
}

.divider-25 {
	margin-top: 25px;
}

.divider-30 {
	margin-top: 30px;
}

.divider-40 {
	margin-top: 40px;
}

.divider-50 {
	margin-top: 50px;
}

.divider-60 {
	margin-top: 60px;
}

.divider-70 {
	margin-top: 70px;
}

.divider-80 {
	margin-top: 80px;
}

.divider-90 {
	margin-top: 90px;
}

.divider-100 {
	margin-top: 100px;
}

.divider-120 {
	margin-top: 120px;
}

@media (min-width: 576px) {
	.divider-sm-0 {
		margin-top: 0;
	}

	.divider-sm-10 {
		margin-top: 10px;
	}

	.divider-sm-20 {
		margin-top: 20px;
	}

	.divider-sm-25 {
		margin-top: 25px;
	}

	.divider-sm-30 {
		margin-top: 30px;
	}

	.divider-sm-40 {
		margin-top: 40px;
	}

	.divider-sm-50 {
		margin-top: 50px;
	}

	.divider-sm-60 {
		margin-top: 60px;
	}

	.divider-sm-70 {
		margin-top: 70px;
	}

	.divider-sm-80 {
		margin-top: 80px;
	}

	.divider-sm-90 {
		margin-top: 90px;
	}

	.divider-sm-100 {
		margin-top: 100px;
	}

	.divider-sm-120 {
		margin-top: 120px;
	}
}

@media (min-width: 768px) {
	.divider-md-0 {
		margin-top: 0;
	}

	.divider-md-10 {
		margin-top: 10px;
	}

	.divider-md-20 {
		margin-top: 20px;
	}

	.divider-md-25 {
		margin-top: 25px;
	}

	.divider-md-30 {
		margin-top: 30px;
	}

	.divider-md-40 {
		margin-top: 40px;
	}

	.divider-md-50 {
		margin-top: 50px;
	}

	.divider-md-60 {
		margin-top: 60px;
	}

	.divider-md-70 {
		margin-top: 70px;
	}

	.divider-md-80 {
		margin-top: 80px;
	}

	.divider-md-90 {
		margin-top: 90px;
	}

	.divider-md-100 {
		margin-top: 100px;
	}

	.divider-md-120 {
		margin-top: 120px;
	}
}

@media (min-width: 992px) {
	.divider-lg-0 {
		margin-top: 0;
	}

	.divider-lg-10 {
		margin-top: 10px;
	}

	.divider-lg-20 {
		margin-top: 20px;
	}

	.divider-lg-25 {
		margin-top: 25px;
	}

	.divider-lg-30 {
		margin-top: 30px;
	}

	.divider-lg-40 {
		margin-top: 40px;
	}

	.divider-lg-50 {
		margin-top: 50px;
	}

	.divider-lg-60 {
		margin-top: 60px;
	}

	.divider-lg-70 {
		margin-top: 70px;
	}

	.divider-lg-80 {
		margin-top: 80px;
	}

	.divider-lg-90 {
		margin-top: 90px;
	}

	.divider-lg-100 {
		margin-top: 100px;
	}

	.divider-lg-120 {
		margin-top: 120px;
	}
}

@media (min-width: 1200px) {
	.divider-xl-0 {
		margin-top: 0;
	}

	.divider-xl-10 {
		margin-top: 10px;
	}

	.divider-xl-20 {
		margin-top: 20px;
	}

	.divider-xl-25 {
		margin-top: 25px;
	}

	.divider-xl-30 {
		margin-top: 30px;
	}

	.divider-xl-40 {
		margin-top: 40px;
	}

	.divider-xl-50 {
		margin-top: 50px;
	}

	.divider-xl-60 {
		margin-top: 60px;
	}

	.divider-xl-70 {
		margin-top: 70px;
	}

	.divider-xl-80 {
		margin-top: 80px;
	}

	.divider-xl-90 {
		margin-top: 90px;
	}

	.divider-xl-100 {
		margin-top: 100px;
	}

	.divider-xl-120 {
		margin-top: 120px;
	}
}

li :hover a {
	color: #2996d5 !important;
}

/*
** Common Sections Styles
*/
section {
	position: relative;
}

img {
	max-width: 100%;
}

/*page preloader*/
.preloader {
	background: rgba(255, 255, 255, 0.99);
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 13000;
}

.no-js .preloader,
.lt-ie9 .preloader,
.lt-ie10 .preloader {
	display: none;
}

.preloader_image {
	/* background: url(../images/preloader.gif) no-repeat 50% 50% transparent; */
	height: 256px;
	left: 50%;
	margin: -128px 0 0 -128px;
	position: absolute;
	top: 50%;
	width: 256px;
}

/* animated elements */
.animate {
	visibility: hidden;
}

.no-js .animate,
.lt-ie10 .animate,
.lt-ie9 .animate {
	visibility: visible;
}

.animated {
	visibility: visible !important;
}

/* boxed layout */
#box_wrapper,
#canvas {
	/* overflow: hidden; */
	position: relative;
}

#box_wrapper>section,
#box_wrapper>div>section {
	clear: both;
	position: relative;

}

#canvas.boxed {
	padding: 1px 0;
}

body.boxed {
	height: auto;
	position: static;
	width: auto;
}


.boxed .container {
	padding-left: 50px;
	padding-right: 50px;
}

@media (max-width: 500px) {
	.boxed .container {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.boxed #box_wrapper.container {
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
	margin-top: 120px;
	padding: 0;
}

@media (min-width: 1600px) {
	.boxed #box_wrapper.container {
		max-width: 1560px;
	}
}

.boxed #box_wrapper.container.top-bottom-margins {
	margin-bottom: 120px;
	margin-top: 150px;
}

/*
** Buttons
 */
.btn:hover {
	cursor: pointer;
}

.btn.focus,
.btn:focus {
	box-shadow: 0 0 3px 0 rgba(100, 100, 100, 0.75);
}

/*color background buttons*/
.btn-maincolor,
.btn-darkgrey {
	color: #fff;
}

.btn-maincolor {
	background-color: #46bcff;
	border: 4px solid #46bcff;
}

.btn-whitecolor {
	background-color: #fff;
	border: 4px solid #fff;
	color: #46bcff;
}

.btn-darkgrey {
	background-color: #323232;
	border: 4px solid #323232;
}

/*color outline buttons*/
.btn-outline-maincolor,
.btn-outline-maincolor2,
.btn-outline-maincolor3,
.btn-outline-darkgrey {
	background-color: transparent;
}

.btn-outline-maincolor {
	border-color: #46bcff;
	color: #1f2732;
}

.btn-outline-darkgrey {
	border-color: #323232;
	color: #323232;
}


/*Dividers, margins and padding helpers*/
/* margins */
/*top&bottom equal margins - dividers*/
.my-10 {
	margin-bottom: 10px;
	margin-top: 10px;
}

.my-15 {
	margin-bottom: 15px;
	margin-top: 15px;
}

.my-20 {
	margin-bottom: 20px;
	margin-top: 20px;
}

.my-30 {
	margin-bottom: 30px;
	margin-top: 30px;
}

.my-40 {
	margin-bottom: 40px;
	margin-top: 40px;
}

.my-50 {
	margin-bottom: 50px;
	margin-top: 50px;
}

.my-60 {
	margin-bottom: 60px;
	margin-top: 60px;
}

/*left&right equal margins*/
.mx-10 {
	margin-left: 10px;
	margin-right: 10px;
}

.mx-15 {
	margin-left: 15px;
	margin-right: 15px;
}

.mx-20 {
	margin-left: 20px;
	margin-right: 20px;
}

.mx-30 {
	margin-left: 30px;
	margin-right: 30px;
}

.mx-40 {
	margin-left: 40px;
	margin-right: 40px;
}

.mx-50 {
	margin-left: 50px;
	margin-right: 50px;
}

.mx-60 {
	margin-left: 60px;
	margin-right: 60px;
}

/*top margins*/
.mt-10 {
	margin-top: 10px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.mt-50 {
	margin-top: 50px;
}

.mt-60 {
	margin-top: 60px;
}

/*bottom margins*/
.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-60 {
	margin-bottom: 60px;
}

/*top right bottom left margins*/
.m-10 {
	margin: 10px;
}

.m-15 {
	margin: 15px;
}

.m-20 {
	margin: 20px;
}

.m-30 {
	margin: 30px;
}

.m-40 {
	margin: 40px;
}

.m-50 {
	margin: 50px;
}

.m-60 {
	margin: 60px;
}

/* paddings */
/*top&bottom equal paddings - dividers*/
.py-10 {
	padding-bottom: 10px;
	padding-top: 10px;
}

.py-15 {
	padding-bottom: 15px;
	padding-top: 15px;
}

.py-20 {
	padding-bottom: 20px;
	padding-top: 20px;
}

.py-30 {
	padding-bottom: 30px;
	padding-top: 30px;
}

.py-40 {
	padding-bottom: 40px;
	padding-top: 40px;
}

.py-50 {
	padding-bottom: 50px;
	padding-top: 50px;
}

.py-60 {
	padding-bottom: 60px;
	padding-top: 60px;
}

/*left&right equal paddings*/
.px-10 {
	padding-left: 10px;
	padding-right: 10px;
}

.px-15 {
	padding-left: 12px;
	padding-right: 12px;
}

.px-20 {
	padding-left: 20px;
	padding-right: 20px;
}

.px-30 {
	padding-left: 30px;
	padding-right: 30px;
}

.px-40 {
	padding-left: 40px;
	padding-right: 40px;
}

.px-50 {
	padding-left: 50px;
	padding-right: 50px;
}

.px-60 {
	padding-left: 60px;
	padding-right: 60px;
}

/*top paddings*/
.pt-10 {
	padding-top: 10px;
}

.pt-15 {
	padding-top: 15px;
}

.pt-20 {
	padding-top: 20px;
}

.pt-30 {
	padding-top: 30px;
}

.pt-40 {
	padding-top: 40px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-60 {
	padding-top: 60px;
}

/*bottom paddings*/
.pb-10 {
	padding-bottom: 10px;
}

.pb-15 {
	padding-bottom: 15px;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-30 {
	padding-bottom: 30px;
}

.pb-40 {
	padding-bottom: 40px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-60 {
	padding-bottom: 60px;
}

/*top right bottom left paddings*/
.p-10 {
	padding: 10px;
}

.p-15 {
	padding: 15px;
}

.p-20 {
	padding: 20px;
}

.p-30 {
	padding: 30px;
}

.p-40 {
	padding: 40px;
}

.p-50 {
	padding: 50px;
}

.p-60 {
	padding: 60px;
}


/*
** Widgets
*/

.widget {
	position: relative;
}

.widget>h6,
.widget .widget-title {
	font-size: 24px;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 27px;
}

.widget>h2:last-child {
	line-height: 1;
}

.widget li.media {
	margin-bottom: 20px;
}

.widget li.media img {
	max-width: 80px;
}

.widget li.media h3,
.widget li.media h4 {
	font-size: inherit;
	font-weight: 700;
	line-height: 1.4;
	margin-bottom: 0.5em;
	text-transform: none;
}

.widget li.media p {
	line-height: 1.4;
	margin-bottom: 0.5em;
}

.page_footer .widget {
	margin-bottom: 50px;
}

.links:hover {
	text-decoration: underline;
}

/* footer:before {
	background-attachment: fixed;
	background-color: #1f273233;
	background-repeat: no-repeat;
	background-size: cover;
	content: "";
	height: 190px;
	left: 0;
	position: absolute;
	top: -50px !important;
	-webkit-transform: skewY(3deg);
	-ms-transform: skewY(3deg);
	transform: skewY(3deg);
	width: 50%;
	z-index: -1;
} */
/* footer::after {
	background-attachment: fixed;
	background-color: #1f273233;
	background-repeat: no-repeat;
	background-size: cover;
	content: "";
	height: 190px;
	opacity: 1;
	position: absolute;
	right: 0;
	top: -50px;
	-webkit-transform: skewY(-3deg);
	-ms-transform: skewY(-3deg);
	transform: skewY(-3deg);
	width: 50%;
	z-index: -1;
} */

/* affix-aside */
.affix-aside {
	position: relative;
}

.affix-aside.affix {
	position: fixed;
	top: 100px;
}

.affix-aside.affix-bottom {
	position: absolute !important;
}

.affix-aside.affix-top {
	left: auto !important;
	width: inherit !important;
}

@media (max-width: 991px) {

	.affix-aside,
	.affix-aside.affix-top,
	.affix-aside.affix,
	.affix-aside.affix-bottom {
		position: static !important;
		width: 100% !important;
	}
}


/*
** Main Menu styles
*/
.nav-wrap {
	align-items: center;
	-webkit-box-align: center;
	-webkit-box-pack: justify;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 991px) {
	.nav-wrap {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
}

.nav-wrap>.top-nav+*:not(.top-includes) {
	-webkit-box-flex: 100;
	flex-grow: 100;
	-ms-flex-positive: 100;
	padding-left: 30px;
	padding-right: 30px;
}

/* Desktop menu - (min 1200px) styles */

@media (min-width: 1200px) {
	.top-nav {
		align-items: center;
		-webkit-box-align: center;
		-webkit-box-flex: 10;
		-ms-flex-align: center;
		flex-grow: 10;
		-ms-flex-positive: 10;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		position: relative;
	}

	.justify-nav-end .top-nav {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.justify-nav-center .top-nav {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	/* menu layout */
	.sf-menu {
		display: block;
	}

	.sf-menu,
	.sf-menu ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.sf-menu li {
		position: relative;
	}

	.sf-menu li>ul {
		display: none;
		position: absolute;
		top: 100%;
		z-index: 99;
	}

	.sf-menu>li>a::after {
		color: #000;
		content: "\f111";
		font-family: FontAwesome;
		font-size: 5px;
		margin-left: 0;
		position: relative;
		right: -20px;
		top: -3px;
	}

	.sf-menu li:hover>ul,
	.sf-menu li.sfHover>ul {
		display: block;
	}

	.sf-menu a {
		display: block;
		position: relative;
	}

	/* menu skin */
	.sf-menu a {
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 0em;
		line-height: 1.6em;
		padding: 13px 0 13px;
		text-decoration: none;
		text-transform: none;
		-webkit-transition-property: color, background-color, border-color, padding-top, padding-bottom;
		transition-property: color, background-color, border-color, padding-top, padding-bottom;
		zoom: 1;
	}

	.sf-menu li {
		*white-space: normal;
		white-space: nowrap;
	}

	.sf-menu>li {
		display: inline-block;
		position: relative;
	}

	.sf-menu.nav>li:first-child>a {
		margin-left: 0;
	}

	.sf-menu.nav>li:last-child>a {
		margin-right: 0;
	}

	.sf-menu>li>a {
		line-height: 14px;
		margin: 0 15px;
		padding-bottom: 28px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 28px;
		-webkit-transition-duration: 0.15s;
		transition-duration: 0.15s;
	}

	.affix .sf-menu>li>a {
		padding-bottom: 15px;
		padding-top: 15px;
	}
}

@media (min-width: 1200px) and (min-width: 1500px) {
	.sf-menu>li>a {
		margin: 0 20px;
	}
}

@media (min-width: 1200px) {

	.sf-menu>li>a:hover,
	.sf-menu>li.active>a {
		color: #46bcff;
	}

	.nav-narrow .sf-menu>li {
		padding: 0;
	}

	.nav-narrow .sf-menu>li>a {
		margin-left: 29px;
		margin-right: 29px;
		padding-bottom: 26px;
		padding-top: 26px;
	}

	.nav-narrow .sf-menu>li.active>a:before {
		background-color: #46bcff;
		content: "";
		height: 1px;
		left: -20px;
		position: absolute;
		right: -20px;
		top: 0;
		z-index: 1;
	}

	.nav-bordered .sf-menu>li+li:before {
		background-color: rgba(255, 255, 255, 0.15);
		bottom: 0;
		content: "";
		left: -2px;
		position: absolute;
		top: 0;
		width: 1px;
	}

	.cs .sf-menu>li>a {
		color: #fff;
	}

	.cs .sf-menu>li>a:hover,
	.cs .sf-menu>li.active>a {
		color: #46bcff;
	}

	.ds .sf-menu>li>a {
		color: #000;
	}

	.ds .sf-menu>li>a:hover,
	.ds .sf-menu>li.active>a {
		color: #000;
		/* padding-bottom: 5px !important; */
		border-bottom: 1px solid #000;
	}

	.ls .sf-menu>li>a {
		color: #323232;
	}

	.ls .sf-menu>li>a:hover,
	.ls .sf-menu>li.active>a {
		color: #46bcff;
	}

	.ls .sf-menu>li+li:before {
		background-color: #e1e1e1;
	}

	.intro_layers {
		background: #fff;
		height: 340px;
		width: 380px;
		justify-content: center;
		min-width: 340px;
		border-radius: 50%;
		border: 15px solid #eee;
		padding: 25px 25px !important;
		max-width: 340px;
		margin-top: 80px;
	}

	.sf-menu li:hover,
	.sf-menu li.sfHover {
		/* only transition out, not in */
		-webkit-transition: none;
		transition: none;
	}

	/* second and next levels */
	.sf-menu>li .mega-menu,
	.sf-menu li>ul {
		/* allow long menu items to determine submenu width */
		*width: 10em;
		background-color: #fff;
		border-top: 4px solid #46bcff;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		list-style: none;
		/* margin-top: 5px; */
		min-width: 10em;
		padding: 28px 0 28px;
		text-align: center;
		/* no auto sub width for IE7, see white-space comment below */
	}

	.sf-menu>li .mega-menu {
		padding-left: 0;
		padding-right: 0;
	}

	.sf-menu.nav ul a {
		color: #323232;
		padding-left: 100px;
		padding-right: 100px;
	}

	.sf-menu .mega-menu ul {
		background: transparent;
		box-shadow: none;
		padding: 0;
	}

	.sf-menu .mega-menu ul a {
		padding-left: 0;
		padding-right: 0;
	}

	.sf-menu ul a:hover,
	.sf-menu ul li.active>a {
		color: #46bcff;
	}

	/* third level */
	.sf-menu ul ul {
		margin-top: 0;
		top: -32px;
	}

	.sf-arrows ul .sf-with-ul:after {
		content: "\f105";
		font-family: "FontAwesome";
		padding-left: 7px;
	}
}

.sf-menu i[class] {
	font-size: 1.1em;
	margin-right: 7px;
}

/* hidden menu items */

@media (min-width: 1200px) {
	.sf-menu .sf-xl-hidden {
		display: none !important;
	}
}

@media (max-width: 1199px) {
	.sf-more-li {
		display: none;
	}
}

.sf-menu>li:last-child>a:after {
	display: none;
}

.toggle-menu-header .page_header_side {
	bottom: 0;
	left: -310px;
	position: fixed;
	top: 0;
	width: 310px;
	z-index: 1005;
}

/* Mobile menu toggler */

@media (min-width: 1200px) {

	.page_header .toggle_menu,
	.page_toplogo .toggle_menu {
		display: none;
	}
}

.toggle_menu {
	cursor: pointer;
	height: 60px;
	margin-left: -30px;
	margin-top: -30px;
	opacity: 0.95;
	padding: 0 !important;
	position: absolute;
	right: 0;
	top: 50%;
	visibility: visible;
	width: 60px;
	z-index: 1002;
}

.page_header_wrapper .toggle_menu {
	z-index: 1003;
}

.vertical-item.text-center {
	margin: auto;
	background: #eaf8ff;
	padding: 5px;
	border-radius: 20px;
	border: 10px double #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 160px;
}

.item-content h6 {
	font-size: 15px;
	letter-spacing: 2px !important;
	line-height: 20px;
}

.page_header_wrapper.ds.affix-wrapper .nav.sf-menu a {
	color: #000;
}

.page_header_wrapper.ds.affix-wrapper .nav.sf-menu a:after {
	color: #000;
}

.ls .toggle_menu {
	background-color: #fff;
}

.ls .toggle_menu:before,
.ls .toggle_menu span,
.ls .toggle_menu span:before,
.ls .toggle_menu span:after {
	background-color: #323232;
}

.page_toplogo .toggle_menu {
	left: auto;
	margin: 0;
	position: fixed;
	right: 0;
	top: 0;
}

.toggle_menu:hover span:before {
	top: 13px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.toggle_menu:hover span:after {
	top: 13px;
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.toggle_menu:before,
.toggle_menu span,
.toggle_menu span:before,
.toggle_menu span:after {
	background-color: #fff;
	content: "";
	display: block;
	height: 2px;
	left: 0;
	position: absolute;
	-ms-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	width: 31px;
}

.toggle_menu:before,
.toggle_menu span {
	color: #fff;
	content: "menu";
	font-size: 9px;
	font-weight: 800;
	left: 13px;
	letter-spacing: 1px;
	text-transform: uppercase;
	top: 24%;
	width: 0;
}

.toggle_menu span:before {
	opacity: 1;
	top: 0;
}

.toggle_menu span:after {
	opacity: 1;
	top: 28px;
}

.toggle_menu.mobile-active {
	background-color: #46bcff;
	border: none;
	left: auto;
	margin: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 3000;
}

.toggle_menu.mobile-active:before {
	background-color: #fff;
}

.toggle_menu.mobile-active span {
	background-color: #fff;
}

.toggle_menu.mobile-active span:before,
.toggle_menu.mobile-active span:after {
	opacity: 1;
}

.toggle_menu.active span:before,
.toggle_menu.active span:after {
	opacity: 1;
}

.toggle_menu:hover:before {
	top: 32px;
}

.ls .toggle_menu:before,
.toggle_menu span {
	color: #fff;
}

.toggle_menu.toggle_menu_side.ds {
	background-color: transparent;
}

/* Mobile Menu (max 991px) styles */

@media (max-width: 767px) {
	.nav-wrap {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

@media (max-width: 1199px) {
	.page_header [class*="col"] {
		min-height: 0;
	}

	.top-nav {
		background-color: transparent;
		bottom: 0;
		left: 0;
		opacity: 0;
		position: fixed;
		right: 0;
		top: 0;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		visibility: hidden;
		z-index: 10;
	}

	.mobile-active .top-nav {
		background-color: #111;
		background-color: rgba(0, 0, 0, 0.9);
		opacity: 1;
		visibility: visible;
		z-index: 1002;
	}

	.nav-justified>li>a {
		text-align: left;
	}

	.sf-menu {
		background-color: #fff;
		border-top: 61px solid #46bcff;
		bottom: 0;
		display: block;
		font-size: 12px;
		font-weight: 700;
		left: 0;
		letter-spacing: 0.2em;
		line-height: 1.6em;
		opacity: 0;
		overflow: auto;
		padding: 20px 10px 20px 20px;
		position: fixed;
		right: 100%;
		text-align: left;
		text-transform: uppercase;
		top: 0;
		-webkit-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
	}

	.mobile-active .sf-menu {
		opacity: 1;
		right: 0;
	}

	.sf-menu li {
		position: relative;
	}

	.top-nav .sf-menu a {

		display: block;
		padding: 10px 10px;
		z-index: 2;
	}

	.sf-menu ul {
		list-style: none;
		margin-top: 0 !important;
		padding-left: 10px;
	}

	.sf-menu ul li a {
		font-weight: 500;
		letter-spacing: 0.1em;
	}

	.sf-menu ul li a:before {
		background-color: #46bcff;
		border-radius: 50%;
		bottom: 3px;
		content: "";
		display: inline-block;
		height: 3px;
		margin-right: 10px;
		position: relative;
		width: 3px;
	}

	/* arrows (for all except IE7) */
	.sf-arrows .sf-with-ul {
		padding-right: 3.5em;
	}

	/* styling for both css and generated arrows */
	.sf-arrows .sf-with-ul+.sf-menu-item-mobile-toggler {
		border-radius: 50%;
		height: 30px;
		line-height: 30px;
		position: absolute;
		right: 5px;
		text-align: center;
		top: 5px;
		width: 30px;
		z-index: 1;
	}

	.sf-arrows .sf-with-ul+.sf-menu-item-mobile-toggler:after {
		color: #323232;
		content: "\f105";
		font-family: "FontAwesome";
	}

	.sfHover>.sf-with-ul+.sf-menu-item-mobile-toggler:after {
		content: "\f107";
	}
}

/* Mega Menu */

@media screen and (min-width: 992px) {
	.top-nav ul .mega-menu ul {
		border: none;
		min-width: auto;
	}

	.top-nav .menu-item-has-mega-menu {
		position: relative;
	}

	.top-nav .mega-menu {
		display: none;
		position: absolute;
		top: 100%;
		width: 940px;
		z-index: 10;
	}

	.top-nav .menu-item-has-mega-menu:hover .mega-menu {
		display: block;
	}

	/* row */
	.top-nav .mega-menu-row {
		display: table;
		position: static;
		table-layout: fixed;
	}

	.top-nav .mega-menu-row ul {
		display: block;
		position: static;
	}

	/* column */
	.top-nav .mega-menu-col {
		border-left: 1px solid #e1e1e1;
		display: table-cell;
		padding: 0 15px;
		width: 2000px;
		/* overrides from settings / styling / mega-menu */
	}

	.top-nav .mega-menu-col>a {
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.top-nav .mega-menu-col:first-child {
		border-left: none;
	}

	.top-nav ul ul .mega-menu {
		display: block;
		margin-left: 1px;
		top: -20px;
		width: auto;
	}

	.top-nav ul ul .mega-menu li {
		border: none;
		display: block;
		width: auto;
	}
}

@media screen and (min-width: 1200px) {
	.top-nav .mega-menu {
		width: 1170px;
	}

	.top-nav ul .mega-menu ul {
		min-width: 10em;
	}

	.top-nav .mega-menu-col {
		padding: 0 40px;
	}
}

@media screen and (max-width: 991px) {
	.top-nav .mega-menu {
		position: static;
	}
}

/* side clickable menu styles */
.menu-click {
	padding: 0;
}

.menu-click i {
	margin-right: 10px;
	text-align: center;
	width: 1em;
}

.menu-click .toggle_submenu {
	border-radius: 50%;
	cursor: pointer;
	font-size: 8px;
	height: 3em;
	line-height: 3em;
	opacity: 0.9;
	position: absolute;
	right: 20px;
	text-align: center;
	top: 8px;
	-ms-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	width: 3em;
}

.menu-click .toggle_submenu:before {
	content: "\f078";
	font-family: "FontAwesome";
	height: 3em;
	left: 0;
	line-height: 3em;
	position: absolute;
	top: 0;
	width: 3em;
}

.menu-click ul {
	padding: 0;
}

.menu-click li {
	list-style: none;
	position: relative;
	width: 100%;
}

.menu-click>li {
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}

.menu-click a {
	display: block;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
	line-height: 1.6em;
	padding: 10px 40px;
	text-transform: uppercase;
}

.menu-click a:hover {
	background-color: rgba(150, 150, 150, 0.05);
}

.menu-click .active-submenu>a {
	background-color: rgba(150, 150, 150, 0.05);
}

.menu-click .active-submenu>.toggle_submenu {
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.menu-click li.active>a {
	color: #46bcff;
}

.menu-click ul {
	height: 0;
	opacity: 0;
}

.menu-click ul a {
	padding-bottom: 8px;
	padding-left: 50px;
	padding-top: 8px;
}

.menu-click ul ul a {
	padding-left: 60px;
}

.menu-click ul ul ul a {
	padding-left: 70px;
}

.menu-click ul ul ul ul a {
	padding-left: 80px;
}

.menu-click .active-submenu>div.mega-menu {
	height: auto;
}

.menu-click .active-submenu>ul,
.menu-click .active-submenu>div.mega-menu>ul {
	height: auto;
	opacity: 1;
}

.menu-click ul,
.menu-click div.mega-menu {
	height: 0;
	overflow: hidden;
	-webkit-transition: opacity 0.6s, margin-top 0.4s, margin-bottom 0.4s, height 0.3s ease-in-out;
	transition: opacity 0.6s, margin-top 0.4s, margin-bottom 0.4s, height 0.3s ease-in-out;
}

.menu-click ul li.sfHover>.mega-menu ul {
	display: block;
	height: 100%;
}

.menu-click div.mega-menu>ul {
	height: auto;
	margin-right: 0;
}

/*
** Header
*/
.page_header {
	height: auto;
	left: 0;
	margin: 0;
	padding: 0;
	position: relative;
	right: 0;
	top: 0;
	-webkit-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	width: 100%;
	z-index: 1000;
}

.boxed .page_header,
.page_header.affix {
	box-shadow: 0 2px 15px 0 rgba(100, 100, 100, 0.05);
	opacity: 0.98;
	position: fixed !important;
	z-index: 1001;
}

.boxed .page_header:after,
.page_header.affix:after {
	background-color: transparent;
}

.page_header.affix.s-bordertop::before {
	display: none;
}

@media (max-width: 991px) {
	.page_header.mobile-active {
		opacity: 1;
		z-index: 1003;
	}
}

.page_header.affix-bottom {
	position: fixed;
	top: 0 !important;
}

.page_header .row {
	min-height: 60px;
}

.page_header a {
	display: inline-block;
}

.page_header .btn {
	margin-bottom: 0;
	padding: 10px 28px;
	margin-right: 3px;
}

.header_absolute {
	position: relative;
}

.page_header.ds.affix {
	height: auto !important;
	min-height: auto;
}

.header_absolute .page_header,
.header_absolute .page_header_wrapper {
	left: 0;
	display: flex;
	align-items: center;
	position: absolute;
	right: 0;
	z-index: 10;
	height: auto !important;

	min-height: 100px !important;
}

.header_absolute.header_transparent .page_header_wrapper {
	background-color: transparent;
}

.header_absolute .page_topline,
.header_absolute .page_toplogo,
.header_absolute .page_header_wrapper,
.header_absolute .page_title {
	background-color: #fff;
	border: 5px solid #46bcff;
	/* border-image-source: 
    linear-gradient(
		#00D0FF, 
		#00E0FF
    );
	border-image-slice: 1 */
}

#canvas:not(.boxed) .header_absolute .page_header.affix-top {
	background-color: transparent;
}

.blueborder .page_header_wrapper {
	border-color: #46BCFF;
}

.pt-150 {
	padding-top: 150px;
}

.pb-150 {
	padding-bottom: 150px;
}

.page_topline {
	line-height: 1.4;
}

.page_topline.small-text i {
	font-size: 14px;
}

.page_topline .icon-inline {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.page_topline .social-icons {
	display: inline-block;
}

.page_topline .widget_nav_menu {
	display: inline-block;
}

.page_topline ul {
	margin: 0;
	padding: 0;
}

.page_topline ul li {
	display: inline-block;
}

.page_topline .dropdown-menu {
	margin: 23px 0 0 -25px;
	min-width: auto;
}

.page_topline .dropdown-language a>img {
	bottom: 1px;
	margin-right: 6px;
	max-width: 16px;
	position: relative;
}

.page_topline .owl-news-slider-dates {
	font-weight: 700;
}

.page_topline .topline-news-item {
	height: 2em;
	line-height: 2em;
	margin: 0 90px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.page_topline .topline-news-item .cat-links {
	display: inline;
}

.page_topline .topline-news-nav-arrow {
	border: 1px solid #323232;
	bottom: 20px;
	font-size: 10px;
	line-height: 38px;
	position: relative;
	-webkit-transition: opacity 0.2s ease-in-out;
	transition: opacity 0.2s ease-in-out;
	width: 40px;
}

.page_topline .topline-news-nav-arrow:hover {
	opacity: 0.5;
}

.page_topline .owl-nav>div {
	opacity: 1;
}

@media (min-width: 992px) {
	.page_toplogo .media+.media {
		margin-left: 60px;
	}
}

.page_toplogo .widget_search form,
.page_toplogo .widget_product_search form,
.page_toplogo .widget_search input,
.page_toplogo .widget_product_search input {
	max-width: none;
}

@media (max-width: 991px) {
	.page_toplogo .media {
		margin: 10px;
	}
}

@media (min-width: 1500px) {

	.page_topline .container-fluid,
	.page_toplogo .container-fluid,
	.page_header .container-fluid {
		padding-left: 60px;
		padding-right: 60px;
	}
}

.boxed .main-toggle,
.main-toggle.affix {
	box-shadow: 0 2px 15px 0 rgba(100, 100, 100, 0.05);
	opacity: 0.98;
	padding: 300px;
	position: fixed !important;
	z-index: 1001;
}


/*logo*/
.header_phone a {
	height: 40px !important;
	line-height: 35px;
	padding: 0 20px !important;
	font-size: 12px;
	font-weight: 600;
}

a.logo {
	align-items: center;
	-webkit-box-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
}

a.logo img {
	width: 150px;
	display: inline-block;
	margin-top: -5px;
}

a.logo .logo-text {
	font-size: 34px;
	padding: 0 10px;
}

a.logo .logo-subtext {
	font-size: 22px;
	padding: 0 10px;
}

a.logo .logo-text-bottom {
	font-size: 26px;
}

a.logo .flex-column,
a.logo.flex-column {
	align-items: flex-start;
	-webkit-box-align: start;
	-ms-flex-align: start;
	padding-bottom: 10px;
	padding-top: 10px;
}

a.logo .flex-column.text-center,
a.logo.flex-column.text-center {
	display: inline-block;
}

.text-center a.logo {
	align-items: center;
	-webkit-box-align: center;
	-webkit-box-pack: center;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.header_logo_center a.logo {
	display: inline-block;
	padding: 10px 0;
	position: relative;
	z-index: 2;
}

.header_logo_center.affix a.logo {
	padding: 5px 0;
}

@media (min-width: 992px) {
	.header_logo_center .logo_wrapper {
		align-items: center;
		bottom: 0;
		-webkit-box-align: center;
		-webkit-box-pack: center;
		display: flex;
		display: -webkit-box;
		display: -ms-flexbox;
		-ms-flex-align: center;
		-ms-flex-pack: center;
		justify-content: center;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}
}

/*search modal*/
#search_modal {
	padding: 0 !important;
}

#search_modal .widget {
	background-color: #fff;
	padding: 20px 0;
}

@media (min-width: 1200px) {
	#search_modal .widget:before {
		background: url("../images/logo-light.png") no-repeat 0 0;
		content: "";
		height: 100px;
		opacity: 0.2;
		position: absolute;
		top: 0;
		width: 100%;
	}
}

#search_modal .search-form {
	margin: 0 auto;
	max-width: 960px;
	padding: 0 100px 0 0;
}

#search_modal a {
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
	line-height: 1.6em;
	text-transform: uppercase;
}

#search_modal a:hover {
	color: #46bcff;
	opacity: 1;
}

#search_modal .searchform-respond {
	color: #fff;
	margin: 20px auto;
	width: 300px;
}

#search_modal .form-group {
	margin-bottom: 0;
}

#search_modal .form-control {
	background-color: transparent;
	border: none;
	color: #323232;
	font-size: 28px;
	font-style: italic;
	height: 60px;
	max-width: none;
	width: 100%;
}

#search_modal .form-control:-moz-placeholder {
	color: #808080;
	opacity: 1;
}

#search_modal .form-control::-moz-placeholder {
	color: #808080;
	opacity: 1;
}

#search_modal .form-control:-ms-input-placeholder {
	color: #808080;
}

#search_modal .form-control::-webkit-input-placeholder {
	color: #808080;
}

#search_modal .btn {
	color: #323232;
	font-size: 0;
	margin: 0 60px 0 0;
	padding: 30px 22px 25px;
	width: auto;
}

#search_modal .btn:before {
	color: #323232;
	font-size: 18px;
}

#search_modal .close {
	color: #323232;
	font-size: 30px;
	line-height: 60px;
	min-width: 60px;
	opacity: 1;
	position: absolute;
	right: 0;
	text-align: center;
	text-shadow: none;
	top: 18px;
	z-index: 2;
}

.active-side-header #search_modal {
	right: 310px;
}

.search_modal_button {
	display: inline-block;
	font-size: 14px;
	line-height: 1;
	padding: 0 0 0 10px;
}

@media (max-width: 1199px) {
	.modal_button .search_modal_button {
		margin-right: 50px;
	}
}

.intro_layer.animate.topleftimage {
	z-index: -3;
}

.intro_layer.animate.topleftimage img {
	width: 180px;
	position: relative;
	height: 180px;
	object-fit: cover;
	border-radius: 50%;
	top: 30px;
	min-width: 180px;
	margin-right: -70px;
}

.features-wrap h5 {
	font-size: 23px;
}

.page_slider .flexslider .intro_layer h2 img {
	display: inline-block;
	width: 160px;
	margin-top: -10px;
	margin-bottom: 10px;
}

.intro_layer.width80 {
	width: 80%;
	margin: auto;
}

.intro_layer.width80 ol li {
	padding-right: 50px;
	font-weight: 400;
	padding-left: 20px;
	position: relative;
	margin: 10px 0;
}

.intro_layer.width80 ol li span.float-right {
	position: absolute;
	right: 0;
	top: 0;
}

.intro_layer.width80 ol li span.float-right img {
	width: 17px;
	margin-top: 4px;
}

.intro_layer.width80 ol {
	text-align: left;
	font-size: 11px;
	line-height: 19px;
}

.page_slider .flexslider.slider-shadow {
	padding-bottom: 50px;
}

.page_slider .flexslider.slider-shadow:before,
.page_slider .flexslider.slider-shadow:after {
	background: transparent;
	bottom: 65px;
	box-shadow: 0 25px 20px rgba(0, 0, 0, 0.3);
	content: "";
	height: 20%;
	left: 10px;
	max-width: 400px;
	position: absolute;
	transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	-webkit-transform: rotate(-3deg);
	width: 50%;
	z-index: 1;
}

.page_slider .flexslider.slider-shadow:after {
	left: auto;
	right: 10px;
	-webkit-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	transform: rotate(3deg);
}

.page_slider .intro_layers_wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;

}

.page_slider .intro_after_featured_word {
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1.5em;
	margin-top: 15px;
	text-transform: uppercase;
}

.page_slider .intro_layers {
	-webkit-box-direction: normal;
	-webkit-box-orient: vertical;
	-webkit-box-pack: center;
	display: -webkit-box;
	display: flex;
	display: -ms-flexbox;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	/* justify-content: flex-start; */
	padding: 20px 0;
	width: 100%;
}

.page_slider .flex-control-nav {
	bottom: 55px;
	-webkit-box-direction: normal;
	-webkit-box-orient: horizontal;
	-webkit-box-pack: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-pack: center;
	justify-content: center;
	position: absolute;
	width: 100%;
}

.page_slider .container,
.page_slider img+.container .row {
	height: 100% !important;
	align-items: center;
}

.page_header.ds.affix {
	background: #fff !important;
	border: 3px solid #48bcfe;
}

.ssf-icon img {
	width: 50px !important;
	margin: auto;
	margin-bottom: 10px !important;
}

.ss-features {
	background: #f0faff;
	border-radius: 5px;
	margin-left: 7px;
	margin-right: 7px;
	height: 130px;
	border: 1px solid;
	box-shadow: 0 0 14px 0 rgb(195 246 218);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 30px;
	border: 0;
}

.ss-features a {
	padding: 2px 0;
	font-size: 13px;
	font-weight: 500;
	margin: 0;
	color: #000 !important;
	text-decoration: none !important;
}

.btn-info {
	color: #fff;
	background-color: #1f2732;
	border-color: #1f2732 !important;
}

.btn-info:hover {
	color: #1f2732;
	background-color: #fff;
	border-color: #fff !important;
}

.btn-primary {
	color: #fff !important;
	background-color: #46bcff !important;
	/* background-image: linear-gradient(to right, #00D0FF, #00E0FF ); */
	border-color: #46bcff !important;
}

.btn-primary:hover {
	color: white;
	background-color: #fff;
	border-color: #46bcff !important;
}

.modal-backdrop.show {
	background-color: rgb(0, 0, 0);
	opacity: 0.8;
	z-index: 99999;
}

.modal.fade.show {
	position: fixed;
	z-index: 999999999999 !important;
	opacity: 1 !important;
	height: auto !important;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: rgba(70, 188, 255, 0.8);
	border-radius: 0 !important;
}

.faq-section .accordion.theme-accordion .card .card-body h6 a {
	display: block;
	background: #eaf8ff;
	padding: 5px 15px;
	border-radius: 7px;
}

.faq-section .accordion.theme-accordion .card .card-body hr {
	margin: 15px auto;
}

.faq-section .accordion.theme-accordion .card .card-body h6,
.faq-section .accordion.theme-accordion .card .card-body p {
	border-bottom: 0 !important;
}

#contactus1 .modal-dialog.modal-lg {
	display: block;
}

#contactus1 .modal-dialog.modal-lg .modal-content {
	display: block;
	margin: 50px auto;
}

.modal .close,
.modal .close:hover {
	font-size: 30px;
	font-weight: 500;
	line-height: 1;
	color: #fff;
	text-shadow: 0 1px 0 #fff;
	opacity: 1;
	position: absolute;
	right: 20px;
	top: 0 !important;
}

.modal-dialog {
	height: 100%;
	display: flex;
}

.modal-dialog .modal-content {
	display: flex;
	align-self: center;
	justify-content: center;
	align-content: center;
}

.modal-content {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-clip: padding-box;
	border: none;
	border-radius: .3rem;
	outline: 0;
	background: #46bcff;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
	border-radius: 10px !important;
}


@media (min-width: 767px) {
	.page_slider.team_slider .container-fluid {
		position: inherit;
	}

	.page_slider .cs,
	.page_slider .ds {
		background-color: transparent;
	}

	.page_slider .container-fluid,
	.page_slider .container,
	.page_slider img+.container {
		bottom: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		right: 0;
		top: 0;
	}

	.page_slider [class*="col-"] {
		margin-bottom: 0;
		margin-top: 0;
		min-height: 0;
		position: static;
		z-index: 10;
	}

	.page_slider .intro_layers_wrapper {
		z-index: 5;

	}
}



h4.mb-20.text-center {
	font-size: 29px;
}

.bgimage {
	position: relative !important;
	overflow: visible;
}

.absoluteicon {
	position: absolute;
	width: 25px;
	/* bottom: -25px; */
	top: 0px;
	right: 5px;
	/* text-align: center; */
}

.bgimage::before {
	background-image: url("../images/pattern.png");
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 450px;
	left: -30px;
	margin-left: -230px;
	margin-top: -216px;
	position: absolute;
	top: 0px;
	z-index: -1;
	background-size: auto 450px;
	width: 450px;
	right: auto;
	margin: auto;
	display: block;
	text-align: center;
	background-position: center center;
	opacity: 0.4;
	bottom: 0 !important;
	vertical-align: middle;
}

@media (max-width: 991px) {
	.page_slider .flexslider .slides li:before {
		display: none;
	}
}

.flexslider-overlay {
	background-color: rgba(70, 188, 255, 1);
	bottom: 0;
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 3;
}

.flexslider-bottom:before {
	background-color: #fff;
	bottom: -35px;
	content: "";
	height: 70px;
	left: 0;
	position: absolute;
	-webkit-transform: skewY(3deg);
	-ms-transform: skewY(3deg);
	transform: skewY(3deg);
	width: 50%;
	z-index: 4;
}

.intro_layer.width80 h3 {
	font-size: 13px;
	line-height: 14px;
}

.flexslider-bottom:after {
	background-color: #fff;
	bottom: -35px;
	content: "";
	height: 70px;
	position: absolute;
	right: 0;
	-webkit-transform: skewY(-3deg);
	-ms-transform: skewY(-3deg);
	transform: skewY(-3deg);
	width: 50%;
	z-index: 4;
}

.flexslider-bottom .mouse-button {
	background-image: url("../images/mouse_icon.png");
	background-repeat: no-repeat;
	bottom: 30px;
	content: "";
	display: block;
	height: 60px;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	width: 40px;
	z-index: 4;
}

.page_slider.blog-slide .flexslider .slides li:before {
	background-image: none;
}

/* Direction Nav */
.flex-direction-nav {
	height: 0;
}

.flex-direction-nav a {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 50% 0 0 50%;
	color: #fff;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 80px;
	margin: -40px 0 0;
	opacity: 1;
	overflow: hidden;
	position: absolute;
	text-align: center;
	text-decoration: none;
	top: 48%;
	width: 80px;
	z-index: 10;
}

.flex-direction-nav a:before {
	content: "\f104";
	font-family: "FontAwesome";
	font-size: 20px;
	left: auto;
	position: absolute;
	right: 20px;
	top: 23px;
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 767px) {
	.flex-direction-nav a {
		top: 33.6%;
	}
}

.flex-direction-nav .flex-prev {
	border-radius: 0 50% 50% 0;
	left: -40px;
}

.flex-direction-nav .flex-prev:hover:before {
	right: 40px;
}

.flex-direction-nav .flex-next:hover:before {
	left: 40px;
}

.page_slider .slide2 .intro_before_featured_word {
	text-align: center;
}


.page_slider .slide2 .intro_featured_word {
	font-weight: 200;
	margin-bottom: 30px;
	margin-top: 19px;
}

@media (max-width: 767px) {
	.page_slider .slide2 .intro_featured_word {
		font-size: 56px;
		margin-bottom: 0;
		margin-top: 7px;
	}
}

@media (min-width: 768px) {
	.page_slider .slide2 .intro_featured_word {
		margin-bottom: 0;
		margin-top: 10px;
	}
}

.page_slider .slide2 .page-bottom {
	margin-top: 25px;
}



.page_slider .slide2 .intro_layers_wrapper {
	height: 100%;
}

.page_slider .slide2 .intro_after_featured_word {
	letter-spacing: 2em;
	margin-left: 27px;
}


.intro_layers {
	z-index: 10;
}



/* 
** Template Specific Styles
*/
#box_wrapper>section,
#box_wrapper>div>section {
	overflow: hidden;
}

#box_wrapper>section.page_topline,
#box_wrapper>section.page_toplogo,
#box_wrapper>section.overflow-visible,
#box_wrapper>div>section.overflow-visible {
	overflow: visible;
}

.s-pb-170>[class*='container'] {
	padding-bottom: 170px;
}

.ins-float-icon-box-title {
	color: #1f2732;
	font-weight: 600;
	font-size: 19px;
	margin-top: 20px;
}

a.btn {
	min-width: 170px;
}

p {
	color: #888;
	font-weight: 400;
}

.z-index {
	z-index: 5;
}

.z-index-10 {
	z-index: 10;
}

.mb-25 {
	margin-bottom: 25px;
}

.fw-600 {
	font-weight: 600;
}

.divider-5 {
	margin-top: 5px;
}

.divider-15 {
	margin-top: 15px;
}

.divider-35 {
	margin-top: 35px;
}

.divider-45 {
	margin-top: 45px;
}

.divider-55 {
	margin-top: 55px;
}

.divider-65 {
	margin-top: 65px;
}

.divider-75 {
	margin-top: 75px;
}

.divider-85 {
	margin-top: 85px;
}

.divider-95 {
	margin-top: 95px;
}

.divider-105 {
	margin-top: 105px;
}

.divider-110 {
	margin-top: 110px;
}

.divider-125 {
	margin-top: 125px;
}

.small-margin {
	margin-top: 5px;
}

.dropdown-shopping-cart {
	font-weight: 700;
	position: relative;
}

.page_toplogo .dropdown-shopping-cart {
	margin-left: 24px;
}

.dropdown-shopping-cart i {
	font-size: 28px;
	padding-right: 14px;
}

.page_toplogo .dropdown-shopping-cart i {
	position: relative;
	top: 0.18em;
}

.dropdown-shopping-cart .badge {
	border-radius: 1em;
	left: 24px;
	padding: .28em .5em .2em;
	position: absolute;
}

.page_toplogo .dropdown-shopping-cart .badge {
	top: -0.3em;
}

.dropdown-shopping-cart:hover {
	color: inherit;
}

.dropdown-shopping-cart+.dropdown-menu {
	margin-top: 50px;
}

@media (min-width: 576px) {
	.dropdown-shopping-cart+.dropdown-menu {
		min-width: 430px;
	}
}





@media (min-width: 576px) {

	.page_footer a.border-icon:before,
	.page_footer a.bg-icon:before,
	.page_footer a.color-bg-icon:before {
		height: 4.2em;
		line-height: 4.2em;
		width: 4.2em;
	}
}

.projectFactsWrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.pl-0list li {
	padding-left: 0 !important;
}

.process-part.bg-blue::before,
.process-part.bg-blue::after {
	background-color: #46bcff;
}

#projectFacts .fullWidth {
	padding: 0;
}


.projectFactsWrap .item {
	width: 25%;
	height: 100%;
	padding: 50px 0px;
	text-align: center;
}

.projectFactsWrap .item:nth-child(1) {
	background: rgb(16, 31, 46);
}

.projectFactsWrap .item:nth-child(2) {
	background: rgb(18, 34, 51);
}

.projectFactsWrap .item:nth-child(3) {
	background: rgb(21, 38, 56);
}

.projectFactsWrap .item:nth-child(4) {
	background: rgb(23, 44, 66);
}

.projectFactsWrap .item p.number {
	font-size: 40px;
	padding: 0;
	font-weight: bold;
}

.projectFactsWrap .item p {
	color: rgba(255, 255, 255, 0.8);
	font-size: 18px;
	margin: 0;
	padding: 10px;
	font-family: 'Open Sans';
}


.projectFactsWrap .item span {
	width: 60px;
	background: rgba(255, 255, 255, 0.8);
	height: 2px;
	display: block;
	margin: 0 auto;
}


.projectFactsWrap .item i {
	vertical-align: middle;
	font-size: 50px;
	color: rgba(255, 255, 255, 0.8);
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
	color: #fff;
	text-decoration: none;
	opacity: 1;
}

.projectFactsWrap .item:hover i,
.projectFactsWrap .item:hover p {
	color: white;
}

.projectFactsWrap .item:hover span {
	background: white;
}

@media (max-width: 786px) {
	.projectFactsWrap .item {
		flex: 0 0 50%;
	}
}


.nav.sf-menu.sf-js-enabled.sf-arrows.footer a::after {
	display: none;
}

.nav.sf-menu.sf-js-enabled.sf-arrows.footer a {
	padding: 10px 0 20px;
}

.page_footer .side-icon-box {
	margin: 0 0 20px;
}

.page_footer .side-icon-box>.icon-styled:first-child {
	margin-right: 0;
	position: relative;
	top: 0.5em;
}

.page_footer .side-icon-box .icon-styled+.media-body {
	margin-left: 20px;
}

.page_footer .col-md-12 .widget_social_buttons {
	margin-bottom: 30px;
}

.ss-features:hover .ssf-icon {
	transform: scale(1.2);
}

.ss-features:hover {
	box-shadow: 0 0 20px 0 rgb(255, 255, 255);
}

.vertical-item:hover {
	transform: scale(1.1);
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	z-index: 1;
}

.ssf-icon {
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.btn-success:hover {
	color: #fff;
	background-color: #2996d5;
	border-color: #2996d5 !important;
}

.mainlisting ul {
	list-style: none;
}

.mainlisting ul li img {
	width: 17px;
	position: absolute;
	left: 0px;
	top: 8px;
}

.user-item.active img {
	filter: brightness(1);
}

.mainlisting ul li a img {
	width: 19px;
	border: 1px solid #fff;
	border-radius: 50%;
}

.mainlisting h5::after {
	width: 60px;
	height: 2px;
	background: #000;
	position: absolute;
	bottom: -3px;
	content: "";
	left: 0;
}

.page__block-title::before {
	display: inline-block;
	position: relative;
	bottom: -1px;
	left: -1rem;
	width: .4rem;
	height: 1.4rem;
	content: '';
	background-color: #2996d5;
}

.page__block-title {
	margin-top: 30px !important;
}

.page-title {
	border: 0;
	margin: 0 0 1.6rem 0;
	padding: 0;
	font-size: 23px;
	line-height: 33px;
}

.page__block-title {
	padding-left: 15px;
}

@media (max-width: 1199px) {
	.page_footer .col-md-12 .widget_social_buttons {
		margin-bottom: 0;
	}
}

.mainlisting ul li {
	margin-bottom: 6px;
	color: #000;
	font-size: 500;
	font-size: 14px;
	position: relative;
	padding-left: 25px;
}

.mainlisting h5 {
	font-size: 16px;
	line-height: 30px;
	position: relative;
	margin-bottom: 15px;
	color: #000;
}

.page_footer .col-md-12 .widget_social_buttons a {
	margin: 0 10px 70px;
	width: 40px;
	height: 40px;
	background: #46bcff;
	padding: 0 !important;
	font-size: 20px !important;
	line-height: 30px;
	color: #fff;
	border-radius: 5px;
	border: 3px double #fff;
}


.white-button {
	bottom: -40px;
	display: block;
	margin-bottom: 20px;
	position: relative;
}

@media (max-width: 991px) {
	.white-button {
		margin-bottom: 3px;
	}
}

.white-button .white-btn {
	background-color: #fff;
	border: none;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	color: #1f2732;
	padding: 30px 61px;
}

.white-button .white-btn:hover {
	background-color: #46bcff;
	color: #fff;
}


#steps {
	z-index: 0;
	padding-bottom: 80px !important;
}

.btn.main-btn {
	background: #46bcff;
	border-color: #46bcff;
	color: #fff;
}

.nav.sf-menu.sf-js-enabled.sf-arrows.footer a {
	color: #fff;
	text-transform: none;
	font-size: 15px;
	font-weight: 400;
	padding: 10px 0 10px !important;
	margin: 0;
	letter-spacing: 0;
	display: block !important;
}

.nav.sf-menu.sf-js-enabled.sf-arrows.footer a img {
	width: 150px;
}

footer h4 {
	font-size: 18px;
	margin-bottom: 5px;
	color: #888;
	line-height: 30px;
	text-transform: uppercase;
}

.nav.sf-menu.sf-js-enabled.sf-arrows.footer li {
	display: block;
}

.margin-negative.logofooter {
	width: 150px;
	margin-top: -37px;
}

.whitebg {
	background: #fff;
}

.select-outer select {
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.5);
	height: 45px !important;
	border-radius: 4px;
	text-align: left;
	padding: 0 20px 0 10px;
	color: #fff;
	font-size: 15px;
	letter-spacing: normal;
	font-weight: 500;
	-webkit-appearance: none;
}

.select-outer::after {
	content: "\f107";
	position: absolute;
	right: 10px;
	top: 7px;
	font-family: FontAwesome !important;
	color: #fff;
	font-size: 18px;
}

.select-outer {
	position: relative;
}

/* .process-part:before {
	background-attachment: fixed;
	background-color: #fff;
	background-repeat: no-repeat;
	background-size: cover;
	content: "";
	height: 100px;
	left: 0;
	position: absolute;
	top: -50px;
	-webkit-transform: skewY(3deg);
	-ms-transform: skewY(3deg);
	transform: skewY(3deg);
	width: 50%;
} */
/* .process-part::after {
    background-attachment: fixed;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 100px;
    opacity: 1;
    position: absolute;
    right: 0;
    top: -50px;
    -webkit-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    transform: skewY(-3deg);
    width: 50%;
} */
.page_footer.corner-footer {
	background: #1f2732;
	position: relative;
	z-index: 0;
	min-height: 100%;
}

.text-black {
	color: #000 !important;
}


@media (max-width: 767px) {
	.page_footer.bordered-footer {
		margin: 0;
	}
}

.page_footer.bordered-footer .widget .widget-title {
	font-size: 30px;
	font-weight: 500;
	line-height: 1;
	margin-bottom: 27px;
}

.page_footer.bordered-footer li p {
	color: #fff;
}


@media (min-width: 1200px) {
	.page_header .main-toggle .toggle_menu {
		display: block;
	}
}

.page_header_side.main-toggler .ds .toggle_menu_side {
	background-color: transparent;
	right: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	z-index: 3;
}

.main-toggle .toggle_menu.mobile-active {
	position: absolute;
	right: -60px;
}

.page_header_side.main-toggle {
	bottom: auto;
	left: 78.7%;
	margin-top: 30px;
	-webkit-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	width: auto;
	z-index: 11;
}

@media (max-width: 991px) {
	.page_header_side.main-toggle {
		margin-top: 10px;
	}
}

.page_header_side.main-toggle .header-side-menu {
	text-align: center;
}

.page_header_side.main-toggle .header-side-menu span.toggle_submenu {
	display: none;
}

.page_header_side.main-toggle .menu-click a {
	color: #fff;
	display: inline-block;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.2em;
	line-height: 1.6em;
	padding: 10px 40px;
	text-transform: uppercase;
}

@media (max-width: 991px) {
	.page_header_side.main-toggle .menu-click a {
		font-size: 12px;
		padding: 10px;
	}
}

.page_header_side.main-toggle .active-submenu {
	padding: 5px 0 20px;
}

.page_header_side.main-toggle .widget {
	margin-top: 23px;
}

.page_header_side.main-toggle .widget_icons_list {
	margin-top: 30px;
}

.page_header_side.main-toggle .list-unstyled {
	text-align: center;
}

.page_header_side.main-toggle .social-icons {
	display: block;
	margin-top: 26px;
}

.page_header_side.main-toggle a.logo {
	left: -1.7%;
	margin-bottom: 50px;
	position: relative;
	top: 1px;
}

.page_header_side.main-toggle .scroll-wrapper {
	bottom: 0;
	left: 0;
	opacity: 0;
	position: fixed !important;
	right: 0;
	top: 0;
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	z-index: 2;
}

.main-toggle.menu-click a {
	color: #fff1;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
	line-height: 1.6em;
	padding: 10px 40px;
	text-transform: uppercase;
}

.page_header_side.main-toggle.active-slide-side-header .scroll-wrapper {
	background-color: #1f2732;
	opacity: 1;
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}

.page_header_side.main-toggle.ds .scroll-wrapper {
	background-color: rgba(31, 39, 50, 0.9);
}

.apsc-inner-block {
	color: #fff;
}

.copyright-ls p {
	color: #000;
}

.header_absolute.header_transparent .page_header.ds.affix-top {
	border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}


.dropdown select {
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}


/* .process-part {
	background: #e8e8e8;
} */
.slides {
	padding: 0;
}

section.top_white_line_big .container:before {
	/* background-image: url("../images/white_line_big.png"); */
	background-repeat: no-repeat;
	content: "";
	display: block;
	height: 134px;
	left: 50%;
	margin-left: -2px;
	position: absolute;
	top: 0;
	width: 4px;
	z-index: 4;
}

.step-center-part.last:after {
	display: none;
}

.pricing-page .pricing-plan .plan-button a {
	background-color: #fff;
}

.sf-menu>li.sf-logo-li>a:after {
	display: none;
}

.maintenance {
	margin-top: -60px;
}

.tog-ls.ls .toggle_menu:before,
.tog-ls.ls .toggle_menu span {
	color: #1f2732;
}

.tog-ls.ls .toggle_menu {
	background-color: transparent;
}

.toggle-menu-ls .toggle_menu:before,
.toggle-menu-ls .toggle_menu span,
.toggle-menu-ls .toggle_menu span:before,
.toggle-menu-ls .toggle_menu span:after {
	background-color: #fff;
}

.toggle-menu-ls .toggle_menu {
	background-color: transparent;
}

.search-box {
	background: #fff;
	border-radius: 70px;
	/* height: 50px; */
	left: 50%;
	width: 40px;
	height: 40px;
	padding: 0 8px;
	position: absolute;
	top: 100%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	/* border: 5px solid #00D0FF; */
	border: 5px solid #46bcff;
	min-width: 50px;
	transition: width 600ms ease-out, height 600ms ease-out;

}

.search-box-expand {
	background: #fff;
	border-radius: 70px;
	/* height: 50px; */
	left: 50%;
	width: 400px;
	height: 40px;
	padding: 0 8px;
	position: absolute;
	top: 100%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	/* border: 5px solid #00D0FF; */
	border: 5px solid #46bcff;
	min-width: 50px;
	transition: width 600ms ease-out, height 600ms ease-out;

}

.signInHome {
	color: #46bcff;
	background-color: #fff;
	border-color: #46bcff !important;
}

.page_header.ds.affix .search-box {
	background: #fff;
	border-radius: 70px;
	height: 50px;
	left: 50%;
	padding: 0;
	position: absolute;
	top: 100%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	border: 3px solid #46bcff;
	min-width: 50px;
}

.search-box i {
	color: #1f2732;
}

.search-box i:hover {
	color: #46bcff;
}

@media (max-width: 991px) {
	.search-box {
		display: none;
	}
}

.page_header.ds.affix .search-box .search-btn {
	height: 40px;
	width: 33px;
	margin-right: 5px;
}

.search-btn {
	align-items: center;
	background: #fff;
	border-radius: 50%;
	-webkit-box-align: center;
	-webkit-box-pack: center;
	color: #e74c3c;
	position: absolute;
	top: 12px;
	right: 12px;
	display: -webkit-box !important;
	display: flex !important;
	display: -ms-flexbox !important;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	float: right;
	/* height: 40px; */
	justify-content: center;
	/* width: 40px; */
}

.search-btn-expand {
	align-items: center;
	background: #fff;
	border-radius: 50%;
	-webkit-box-align: center;
	-webkit-box-pack: center;
	color: #e74c3c;
	position: absolute;
	top: 15px;
	right: 12px;
	display: -webkit-box !important;
	display: flex !important;
	display: -ms-flexbox !important;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	float: right;
	/* height: 40px; */
	justify-content: center;
	/* width: 40px; */
}

/* .search-box:hover > .search-text {
	-webkit-transition: 0.8s;
	transition: 0.8s;
	width: 240px;
	height: 40px;
	margin-right: 25px;
} */

.search-box:hover>.search-btn {
	background: white;
}

input[type="text"].search-text {
	background: none;
	border: none;
	color: #7f7f7f;
	font-size: 12px;
	letter-spacing: 0.2em;
	/* line-height: 40px; */
	height: 37px;
	outline: none;
	padding: 0;
	text-align: center;
	text-transform: none;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	width: 100%;
}


body.js-lock-scroll {
	overflow: hidden !important;
}

body.js-lock-pointer {
	pointer-events: none !important;
}

.overlay,
.modal-wrapper {
	position: fixed;
	display: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

.overlay {
	z-index: 10000;
	overflow: hidden;
}

.overlay.js-open {
	display: block;
}

.overlay__ripple {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	background: #fff;
	opacity: 0;
	transform-origin: 0 0;
	backface-visiblity: hidden;
	transform: scale(0) translate3d(-50%, -50%, 0);
	transition: opacity 600ms ease-in-out, transform 600ms cubic-bezier(0.7, 0, 0.6, 0.8);
}

.overlay[data-modal=nav] .overlay__ripple {
	background: #99cc66;
}

.js-animate-in .overlay__ripple {
	opacity: 0.8;
	transform: scale(1) translate3d(-50%, -50%, 0);
}

.js-animate-done .overlay__ripple {
	border-radius: 0;
	transform: none;
	transition: opacity 600ms ease-in-out;
}


.modal-wrapper {
	z-index: 10001;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	display: -ms-flexbox;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.overlay:not(.js-open)+.modal-wrapper {
	display: none;
}

.overlay.js-animate-in.js-animate-done+.modal-wrapper {
	overflow: auto;
}

.overlay[data-modal="test"].js-open+.modal-wrapper .modal[data-modal="test"] {
	display: block;
}

.modal-wrapper:after,
.modal-wrapper:before {
	display: block;
	content: "";
}

.overlay[data-modal="test"].js-animate-in+.modal-wrapper .modal[data-modal="test"] {
	opacity: 1;
	transform: scale(1);
}

.modal {
	box-sizing: border-box;
	position: relative;
	display: none;
	width: 500px;
	margin: 1.75rem;
	padding: 1.3125rem;
	padding-bottom: 1.3125rem;
	padding-bottom: 1.3125rem;
	padding-bottom: 1.3125rem;
	padding-bottom: 3.9375rem;
	background: #46bcff;
	box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
	opacity: 0;
	transform: translateZ(0);
	transition: opacity 300ms 150ms ease-in-out, transform 300ms 150ms ease-in-out;
	max-width: 100%;
	border-radius: 10px;
}

#modal-container {
	position: fixed;
	display: table;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	transform: scale(0);
	z-index: 1;
}

#modal-container.four {
	z-index: 0;
	transform: scale(1);
}

#modal-container.four .modal-background {
	background: rgba(0, 0, 0, 0.7);
}

#modal-container.four .modal-background .modal {
	animation: blowUpModal 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.four+.content {
	z-index: 1;
	animation: blowUpContent 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.four.out .modal-background .modal {
	animation: blowUpModalTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container.four.out+.content {
	animation: blowUpContentTwo 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

#modal-container .modal-background {
	display: table-cell;
	background: rgba(0, 0, 0, 0.8);
	text-align: center;
	vertical-align: middle;
}

#modal-container .modal-background .modal {
	background: white;
	padding: 50px;
	display: inline-block;
	border-radius: 3px;
	font-weight: 300;
	position: relative;
}

#modal-container .modal-background .modal h2 {
	font-size: 25px;
	line-height: 25px;
	margin-bottom: 15px;
}

#modal-container .modal-background .modal p {
	font-size: 18px;
	line-height: 22px;
}

#modal-container .modal-background .modal .modal-svg {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 3px;
}

#modal-container .modal-background .modal .modal-svg rect {
	stroke: #fff;
	stroke-width: 2px;
	stroke-dasharray: 778;
	stroke-dashoffset: 778;
}


@keyframes blowUpContent {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	99.9% {
		transform: scale(2);
		opacity: 0;
	}

	100% {
		transform: scale(0);
	}
}

@keyframes blowUpContentTwo {
	0% {
		transform: scale(2);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes blowUpModal {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes blowUpModalTwo {
	0% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}

.item-media img {
	width: 40px;
}

.ls h1,
.ls h2,
.ls h3,
.ls h4,
.ls h5,
.ls h6 {
	color: #1f2732;
}

.tab-content {
	background: #eaf8ff;
	border-radius: 10px;
	box-shadow: 0px 0px 5px #ccc;
}

.datalist {
	background: #fff;
	padding: 12px 15px;
	border-radius: 10px;
	border: 1px solid #eee;
	height: 100%;
}

.border-3 {
	border-width: 2px !important;

}

.datalist img {
	width: 20px;
}

.righticon img {
	width: 22px;
}

.flexslider .slides>li {
	height: 100vh;
}

.nav-tabs .nav-link {
	position: relative;
}

/* .nav-tabs .nav-link.active::after, .nav-tabs:hover .nav-link.active::after, .nav-tabs .nav-link:hover::after {
    border-top: 20px solid #46BCFF;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    content: "";
    display: inline-block;
    right: 0;
    position: absolute;
    bottom: -25px;
    left: 0;
    margin: auto;
    text-align: center;
    width: 20px;
} */
.nav-link.active.vertical-item.text-center h6,
.nav-link.vertical-item.text-center:hover h6 {
	color: #fff;
}

.lefticon {
	width: 45px;
	min-width: 45px;
	height: 45px;
	overflow: hidden;
	background: #fff;
	/* border-radius: 100%; */
	/* border: 2px solid #1dadff; */
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.datalist h4 {
	font-size: 15px !important;
	line-height: 22px;
	margin-bottom: 0;
}

.lefticon img {
	width: 70%;
}

.list-bordered {
	list-style: none;
	overflow: hidden;
	padding: 0;
}

.nav.nav-tabs {
	display: flex;
	align-content: center;
	justify-content: center;
	margin-bottom: 30px;
}

.nav.nav-tabs .nav-item {
	min-width: 40%;
}

.list-bordered li {
	border-bottom: 1px solid #e1e1e1;
	border-top: 1px solid #e1e1e1;
	padding-bottom: 15px;
	padding-top: 14px;
}

.list-bordered li+li {
	border-top: none;
}

.list-bordered.no-top-border>li:first-child {
	border-top: none;
}

.list-bordered.no-bottom-border>li:last-child {
	border-bottom: none;
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: #2196F3;
}

input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

@media (min-width:1380px) {
	.container {
		max-width: 1170px;
	}
}



bg-color {
	background-color: #1f2732;
}

.signup-step-container {
	padding: 160px 0px;
	padding-bottom: 160px;
}

.wizard .nav-tabs {
	position: relative;
	margin-bottom: 0;
	border-bottom-color: transparent;
}

.wizard>div.wizard-inner {
	position: relative;
	margin-bottom: 90px;
	text-align: center;
}

.connecting-line {
	height: 2px;
	background: #e0e0e0;
	position: absolute;
	width: 75%;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: 25px;
	z-index: 1;
}

.wizard .nav-tabs>li.active>a,
.wizard .nav-tabs>li.active>a:hover,
.wizard .nav-tabs>li.active>a:focus {
	color: #555555;
	cursor: default;
	border: 0;
	border-bottom-color: transparent;
}

span.round-tab {
	width: 50px;
	height: 50px;
	line-height: 50px;
	display: inline-block;
	border-radius: 50%;
	background: #ccc;
	z-index: 2;
	position: absolute;
	left: -25px;
	text-align: center;
	font-size: 16px;
	color: #0e214b;
	font-weight: 500;
	border: 1px solid #ddd;
	top: 0;
}

span.round-tab i {
	color: #555555;
}

.wizard li.active span.round-tab {
	background: #4bbdfe;
	color: #fff;
	width: 50px;
	line-height: 45px;
	height: 50px;
	border-color: #4bbdfe;
}

.wizard li.active span.round-tab i {
	color: #5bc0de;
}

.wizard .nav-tabs>li.active>a i {
	color: #4bbdfe;
}

.wizard .nav-tabs>li {
	width: 25%;
}

.wizard li:after {
	content: " ";
	position: absolute;
	left: 46%;
	opacity: 0;
	margin: 0 auto;
	bottom: 0px;
	border: 5px solid transparent;
	border-bottom-color: red;
	transition: 0.1s ease-in-out;
}

.wizard .nav-tabs>li a {
	width: 30px;
	height: 30px;
	margin: 20px auto;
	border-radius: 100%;
	padding: 0;
	background-color: transparent;
	position: relative;
	top: 0;
}

.wizard .nav-tabs>li a i {
	position: absolute;
	top: 70px;
	font-style: normal;
	font-weight: 400;
	white-space: nowrap;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 12px;
	font-weight: 700;
	color: #000;
}

.wizard .nav-tabs>li a:hover {
	background: transparent;
}

.wizard .tab-pane {
	position: relative;
	padding-top: 20px;
}

.width20 {
	width: 20px;
}

.wizard h3 {
	margin-top: 0;
}

.prev-step,
.next-step {
	margin-top: 30px;
}

.next-step {
	background-color: #49bdff;
}

.skip-btn {
	background-color: #cec12d;
}

.step-head {
	font-size: 20px;
	text-align: center;
	font-weight: 500;
	margin-bottom: 20px;
}

.term-check {
	font-size: 14px;
	font-weight: 400;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 40px;
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 40px;
	margin: 0;
	opacity: 0;
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 40px;
	padding: 0.375rem 0.75rem;
	font-weight: 400;
	line-height: 2;
	color: #495057;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: 38px;
	padding: 0.375rem 0.75rem;
	line-height: 2;
	color: #495057;
	content: "Browse";
	background-color: #e9ecef;
	border-left: inherit;
	border-radius: 0 0.25rem 0.25rem 0;
}

.footer-link {
	margin-top: 30px;
}

.list-content {
	margin-bottom: 10px;
}

.list-content a {
	padding: 10px 15px;
	width: 100%;
	display: inline-block;
	background-color: #f5f5f5;
	position: relative;
	color: #565656;
	font-weight: 400;
	border-radius: 4px;
}

.list-content a[aria-expanded="true"] i {
	transform: rotate(180deg);
}

.list-content a i {
	text-align: right;
	position: absolute;
	top: 15px;
	right: 10px;
	transition: 0.5s;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #fdfdfd;
}

.list-box {
	padding: 10px;
}

.signup-logo-header .logo_area {
	width: 200px;
}

.signup-logo-header .nav>li {
	padding: 0;
}

.signup-logo-header .header-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.list-inline li {
	display: inline-block;
}

.pull-right {
	float: right;
}

/*-----------custom-checkbox-----------*/
/*----------Custom-Checkbox---------*/
input[type="checkbox"] {
	position: relative;
	display: inline-block;
	margin-right: 5px;
}

input[type="checkbox"]::before,
input[type="checkbox"]::after {
	position: absolute;
	content: "";
	display: inline-block;
}

input[type="checkbox"]::before {
	height: 16px;
	width: 16px;
	border: 1px solid #999;
	left: 0px;
	top: 0px;
	background-color: #fff;
	border-radius: 2px;
}

input[type="checkbox"]::after {
	height: 5px;
	width: 9px;
	left: 4px;
	top: 4px;
}

input[type="checkbox"]:checked::after {
	content: "";
	border-left: 1px solid #fff;
	border-bottom: 1px solid #fff;
	transform: rotate(-45deg);
}

input[type="checkbox"]:checked::before {
	background-color: #18ba60;
	border-color: #18ba60;
}





.product-single {
	color: inherit
}

.product-single .product-details {
	padding: 0 0 3rem
}

.container-fluid .product-single .product-details {
	padding-top: 4px
}

aside+div .product-single .product-details {
	padding-top: 2px
}

.product-single .product-cat {
	margin-bottom: 1.3rem;
	color: #999;
	font-size: 1.3rem;
	font-weight: 400;
	letter-spacing: -.025em
}

.product-single .product-cat span {
	margin-right: .7rem
}

.product-single .product-name {
	font-size: 30px;
	font-weight: 600;
	letter-spacing: -.025em;
	white-space: normal;
	color: #1f2732;
}

.product-single .product-price {
	display: block;
	margin-bottom: 10px;
	color: #46bcff;
	font-size: 30px;
	font-weight: 600;
	line-height: 36px;
	margin-top: 10px;
}

.product-price del {
	color: #a2a2a2;
	margin-left: 10px;
	font-size: 23px;
}

.product-single .old-price {
	font-size: 2.4rem;
	font-weight: 600
}

.product-single .rating-reviews {
	font-size: 1.2rem
}

.product-single .rating-reviews:not(:hover) {
	color: #999
}

.product-single .ratings-container {
	margin-bottom: 1.9rem;
	font-size: 16px
}

.product-single .ratings-full {
	margin-top: -2px
}

.product-single .ratings::before {
	color: #666
}

.product-single label {
	color: #222;
	font-weight: 600;
	text-transform: uppercase
}

.product-single .product-action {
	display: inline-block
}

.product-name {
	font-size: 30px;
	margin-bottom: 0;
}

.product-meta {
	font-weight: 500;
}

.product-meta span {
	font-weight: 600;
}

.product-short-desc {
	color: #0000ff;
	font-weight: 400;
	font-size: 17px;
	margin-bottom: 20px;
}

.bold-anchor {
	color: #00f;
	border-bottom: 1px solid #00f;
	line-height: 20px;
	padding-bottom: 0 !important;
	height: auto;
}

.breakdowntable {
	background: #eaf8ff;
	border-radius: 10px;
	margin-top: 0;
	margin-bottom: 10px;
}

.select-outer.greytext select,
.select-outer .form-control:focus {
	border-color: #ccc;
	color: #1f2732;
}

.select-outer.greytext::after {
	color: #1f2732;
}

.nav.nav-tabs.customtab a {
	border: 1px solid #e8e8e8;
	border-radius: 60px;
	text-align: center;
	height: 40px;
	line-height: 40px;
	padding: 0;
	background: #e8e8e8;
	color: #1f2732;
}

.nav.nav-tabs.customtab a.active {
	background: #46bcff;
	border-color: #46bcff;
	color: #fff;
}

.nav.nav-tabs.customtab li {
	min-width: 140px;
	width: inherit;
}

.nav.nav-tabs.customtab {
	justify-content: flex-start;
}

.user-item img {
	width: 30px;
	margin-right: 10px;
}

.shadownone {
	box-shadow: none !important;
}

.nav.nav-tabs.customtab a:after,
.nav-tabs.customtab .nav-link.active::after,
.nav-tabs.customtab:hover .nav-link.active::after,
.nav-tabs.customtab .nav-link:hover::after {
	display: none;
}

/*orders*/
.left-side-tabs {
	position: sticky;
	background: #fff;
	margin-top: 75px;
	border-radius: 5px;
	box-shadow: 0 1px 2px 0 #e9e9e9;
	border: 1px solid #eee;
}

.product-left-title h4 {
	font-size: 24px;
	color: #1f2732;
}

.user-item {
	display: flex;
	padding: 12px 20px;
	border-bottom: 1px solid #efefef;
	font-size: 14px;
	font-weight: 400;
	color: #1f2732;
	text-align: left;
	align-items: center;
}

.user-item:last-child {
	border-bottom: 0;
}

.user-item i {
	margin-right: 10px;
	font-size: 18px;
}

.user-item:hover {
	color: #1f2732;
	background: rgba(69, 192, 241, 0.16);
}

.user-item:first-child:hover {
	border-radius: 5px 5px 0 0;
}

.user-item:last-child:hover {
	border-radius: 0 0 5px 5px;
}

.user-item:hover i {
	color: rgb(69, 192, 241);
}

.user-item.active {
	background: #46bcff;
	/* background-image: linear-gradient(to right, #00D0FF, #00E0FF ) !important; */
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	pointer-events: none;
}

.user-item:first-child.active {
	border-radius: 5px 5px 0 0;
}

.user-item:last-child.active {
	border-radius: 0 0 5px 5px;
}

.product-left-title {
	float: left;
}

.product-left-title h2 {
	font-size: 24px;
	font-weight: 600;
	color: #1f2732;
	text-align: left;
	padding-top: 3px;
}

.filter-btn {
	font-size: 14px;
	font-weight: 500;
	float: right;
	display: block;
	height: 40px;
	width: 110px;
	padding: 10px 0;
	text-align: center;
	color: #1f2732;
	background: #fff;
	border-radius: 5px;
	border: 1px solid #efefef;
	text-transform: uppercase;
}

.filter-btn:hover {
	background: #45c0f1;
	color: #fff !important;
	transition: all .2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
}

.product-sort {
	float: right;
	margin-right: 10px;
	margin-top: 1px;
}

.ui.selection.dropdown.vchrt-dropdown {
	border: 1px solid #efefef;
	font-weight: 400;
	color: #1f2732;
	border-radius: 5px;
}

.ui.selection.dropdown.vchrt-dropdown:hover {
	border-color: #efefef;
}

.ui.selection.visible.dropdown.vchrt-dropdown>.text:not(.default) {
	font-weight: 400;
	color: #1f2732;
}

.ui.dropdown.vchrt-dropdown .menu>.item {
	color: #1f2732 !important;
	font-weight: 400 !important;
}

.pdpt-bg {
	/* margin-top: 30px; */
	background: #fff;
	border-radius: 12px;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

.dummyText {
	color: #d3d5d7 !important;
}

.dummyText1 {
	color: #ffff !important;
}



.pdpt-title h4 {
	border-start-end-radius: 12px;
	border-start-start-radius: 12px;
	background: #46bcff;
	/* background: #6edbfd !important; */
	color: #fff;
	padding: 15px 20px;
	font-size: 16px;
	font-weight: 600;
}

.bt-2 {
	border-top: 2px solid #45c0f1;
}

.border_radius:last-child {
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.pdpt-body {
	height: 451px;
	overflow-y: auto;
}

.pdct-dts-1 {
	padding: 0 30px 30px;
}

.pdct-dt-step h4 {
	margin-top: 27px !important;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 12px;
	text-align: left;
}

p.cart-text {
	padding: 0 20px;
	margin-left: 0;
}

.pdct-dt-step p {
	font-size: 14px;
	font-weight: 400;
	color: #3e3f5e;
	line-height: 26px;
	text-align: left;
}

.product_attr {
	font-size: 14px;
	font-weight: 400;
	color: #3e3f5e;
	line-height: 26px;
	text-align: left;
}

.pdpt-title h6 {
	border-bottom: 1px solid #efefef;
	padding: 15px 20px;
	font-size: 14px;
	font-weight: 400;
	color: #1f2732;
	margin-bottom: 0;
}

.order-dtsll {
	padding: 20px;
}

.order-dtsll li {
	display: inline-block;
	vertical-align: top;
}

.order-dt-img {
	background: #f9f9f9;
	padding: 10px;
	border: 1px solid #efefef;
	border-radius: 5px;
}

.order-dt-img img {
	width: 140px;
	height: 100px;
	object-fit: cover;
}

.order-dt47 {
	margin-left: 15px;
}

.order-dt47 h4 {
	font-size: 20px;
	color: #040404;
	margin-bottom: 5px;
	line-height: 24px;
	text-align: left;
	font-weight: 600;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
	margin-top: auto;
}

.order-title {
	font-size: 27px;
	font-weight: 600;
}

.order-title del {
	font-size: 23px;
	font-weight: 500;
}

.order-title span {
	font-size: 12px;
	display: inline-block;
	background: #1f2732;
	color: #fff;
	height: 18px;
	border-radius: 100%;
	width: 18px;
	text-align: center;
	font-weight: 700;
	margin-left: 5px;
	cursor: pointer;
	line-height: 17px;
}

.total-checkout-group {
	padding: 20px 20px 0;
	border-top: 1px solid #fff;
	margin-top: 10px;
}

.cart-total-dil {
	display: flex;
	width: 100%;
	align-items: center;
}

.cart-total-dil h4 {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	color: #1f2732;
	line-height: 24px;
}

.cart-total-dil span {
	color: #8f91ac;
	font-size: 16px;
	margin-left: auto;
	font-weight: 400;
	line-height: 24px;
}

.main-total-cart {
	display: flex;
	padding: 20px;
	border-bottom: 1px solid #e5e5e5;
}

.main-total-cart h2 {
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 600;
	color: #1f2732;
	line-height: 24px;
}

.main-total-cart span {
	color: #45c0f1;
	font-size: 18px;
	margin-left: auto;
	font-weight: 700;
	line-height: 24px;
}

.track-order {
	padding: 20px 0 20px;
}

.track-order h4 {
	margin-left: 20px;
	margin-bottom: 20px;
	font-weight: 700;
	color: #1f2732;
	text-align: left;
}

.bs-wizard {
	border-bottom: solid 1px #e0e0e0;
	padding: 0;
}

.bs-wizard>.bs-wizard-step {
	padding: 0;
	position: relative;
	width: 100%;
}

.bs-wizard>.bs-wizard-step+.bs-wizard-step {}

.bs-wizard>.bs-wizard-step .bs-wizard-stepnum {
	color: #1f2732;
	font-size: 14px;
	margin-bottom: 5px;
	font-weight: 400;
}

.bs-wizard>.bs-wizard-step .bs-wizard-info {
	color: #999;
	font-size: 14px;
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot {
	position: absolute;
	width: 50px;
	height: 50px;
	display: block;
	background: rgba(75, 189, 254, 0.2);
	top: 45px;
	left: 50%;
	margin-top: -15px;
	margin-left: -25px;
	border-radius: 50%;
	z-index: 0;
}

.bs-wizard>.bs-wizard-step>.bs-wizard-dot::after {
	content: ' ';
	width: 34px;
	height: 34px;
	background: rgb(75, 189, 254);
	border-radius: 50px;
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: -1;
}

.bs-wizard-step.complete .bs-wizard-dot span,
.bs-wizard-step.active .bs-wizard-dot span {
	color: #fff;
}

.bs-wizard-dot span {
	z-index: 2;
	color: #000;
	text-align: center;
	width: 50px;
	height: 50px;
	line-height: 50px;
	display: block;
}

.bs-wizard>.bs-wizard-step>.progress {
	position: relative;
	border-radius: 0px;
	height: 8px;
	box-shadow: none;
	margin: 22px 0;
}

.bs-wizard>.bs-wizard-step>.progress>.progress-bar {
	width: 0px;
	box-shadow: none;
	background: rgba(75, 189, 254, 0.4);
}

.bs-wizard>.bs-wizard-step.complete>.progress>.progress-bar {
	width: 100%;
}

.bs-wizard>.bs-wizard-step.active>.progress>.progress-bar {
	width: 50%;
}

.bs-wizard>.bs-wizard-step:first-child.active>.progress>.progress-bar {
	width: 0%;
}

.bs-wizard>.bs-wizard-step:last-child.active>.progress>.progress-bar {
	width: 100%;
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot {
	background-color: rgba(0, 0, 0, 0.1);
}

.bs-wizard>.bs-wizard-step.disabled>.bs-wizard-dot::after {
	opacity: 1;
	background: #ccc;
}

.bs-wizard>.bs-wizard-step:first-child>.progress {
	left: 50%;
	width: 50%;
}

.bs-wizard>.bs-wizard-step:last-child>.progress {
	width: 50%;
}

.bs-wizard>.bs-wizard-step.disabled a.bs-wizard-dot {
	pointer-events: none;
}

.progress {
	background-color: #ddd !important;
}

.bs-wizard {
	margin-top: 0;
	display: flex;
	width: 100%;
}

.main-title-tab h4 {
	font-size: 18px;
	font-weight: 700;
	color: #1f2732;
	text-align: left;
	margin-bottom: 0;
	margin-top: 0;
}

.user-img {
	position: relative;
	display: inline-block;
}

.user-img img {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	border: 2px solid #fff;
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.img-add {
	display: block;
}

.img-add [type="file"] {
	height: 0;
	overflow: hidden;
	width: 0;
	float: left;
}

.img-add [type="file"]+label {
	cursor: pointer;
	display: inline-block;
	outline: none;
	transition: all 0.3s;
	vertical-align: middle;
	margin: 0;
	position: absolute;
	bottom: 5px;
	right: -5px;
	background: #45c0f1;
	color: #fff;
	height: 35px;
	width: 35px;
	border-radius: 100%;
	border: 1px solid #ffff;
	font-size: 16px;
	padding: 0px 0 0 3px;
	line-height: 35px;
	text-align: center;
}

.pdpt-title a {
	color: #fff;
}

.cate-body {
	padding: 30px;
}

.cate-header h4 {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.morecate-bottom {
	display: block;
	text-align: center;
	border-top: 1px solid #efefef;
	padding: 10px;
	font-size: 16px;
	font-weight: 500;
	color: #1f2732;
}

.cate-header {
	background: #1f2732;
	color: #fff;
	padding: 15px 20px;
}

.welcome-text h2 {
	font-size: 24px;
	font-weight: 500;
	color: #1f2732;
	text-align: left;
	margin-bottom: 3px !important;
	margin-top: 30px !important;
}

.ddsh-body {
	padding: 0 20px;
	justify-content: space-between;
}

.ddsh-body h5 {
	font-size: 18px;
	margin-top: 0 !important;
}

.section-heading-subtitle {
	font-size: 19px;
	margin-bottom: 10px;
	text-transform: uppercase;
	font-weight: 500;
	letter-spacing: 1px;
}

.section-heading-title {
	font-size: 33px;
	line-height: 40px;
	color: #46bcff !important;
}

.brands-item.item-style {
	height: 180px;
}

.item-style:hover {
	box-shadow: .125rem .25rem 1.25rem .0875rem rgba(45, 45, 45, .13);
}

.item-style {
	position: relative;
	background-color: #fff;
	border-radius: .625rem;
	overflow: hidden;
	color: #303036;
	height: 100%;
	transition: all .25s;
	margin-bottom: 30px;
}

.brands-item {
	position: relative;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 180px;
}

.brands-item img {
	display: block;
	max-width: 100%;
	max-height: 100%;
}

.item-style::after {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: .625rem;
	border: 1px solid #d4d4e1;
	overflow: hidden;
	pointer-events: none;
	transition: all .25s;
}

.lightgrey {
	background: #f0f0f0;
}

.pt-80 {
	padding-top: 80px !important;
}


.pb-80 {
	padding-bottom: 80px !important;
}

.shop-summary-item {
	padding: 20px 0;
	position: relative;
}

.shop-summary-item::before,
.shop-summary-item::after {
	content: " ";
	display: table;
}

.shop-summary-item img {
	float: left;
	margin-right: 15px;
}

.shop-summary-item .item-info-name-features-price {
	line-height: 25px;
}

.shop-summary-item .item-info-name-features-price h4 {
	margin-top: -3px;
	padding-right: 20px;
	line-height: 24px;
}

[class^="item-info"] h4 a,
[class^="post-info"] h4 a {
	border-bottom: none;
	color: #1a1a1a;
}

.shop-summary-item .item-info-name-features-price .features {
	display: inline-block;
	color: #808080;
}

.shop-summary-item .item-info-name-features-price .quantity {
	color: #808080;
}

.shop-summary-item .item-info-name-features-price .quantity,
.shop-summary-item .item-info-name-features-price .quantity+b,
.shop-summary-item .item-info-name-features-price .price {
	display: inline-block;
	font-weight: bold;
	font-size: 16px;
}

.element-emphasis-weak {
	border: 1px solid #e5e5e5;
	padding: 16px 16px 16px;
	width: 100%;
}

.order-summary.element-emphasis-weak {
	float: left;
}

.order-summary-price dd strong {
	float: right !important;
}

.shop-summary-item img {
	/* float: left; */
	/* margin-right: 15px;
	max-width: 70px;
	position: absolute;
	left: 0;
	top: 25px; */
}

.item-info-name-features-price {
	padding-left: 85px;
}

.element-emphasis-strong .element-header,
.element-emphasis-weak .element-header {
	margin-top: 0;
	margin-bottom: 21px;
	font-size: 16px;
}

.strong-header {
	text-transform: none;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 1px;
}

h4.strong-header {
	font-size: 14px;
}

.checkout+.checkout {
	margin-top: 30px;
}

.element-emphasis-strong {
	border: 5px solid #e5e5e5;
	padding: 25px 25px 31px;
	clear: both !important;
}

form label+input,
form label+select,
form label+select+.chosen-container {
	margin-top: 10px;
}

[class^="item-info"] h4,
[class^="post-info"] h4 {
	margin: 0;
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 7px;
}

.shop-summary-item .item-info-name-features-price {
	line-height: 25px;
}

.shop-summary-item .item-info-name-features-price .price {
	color: #1a1a1a;
}

.order-summary .order-summary-price dt,
.order-summary .order-summary-price dd {
	display: inline-block;
	width: 50%;
	float: left;
	margin-top: 5px;
	margin-left: 0 !important;
	margin-right: 0 !important;
	margin-bottom: 0 !important;
}

.order-summary .order-summary-price dt {
	/* color: #49bdff; */
	font-weight: 600;
}

.order-summary .order-summary-price [class^="total-"] {
	border-top: 1px solid #e6e6e6;
}

.order-summary .order-summary-price dt.total-price,
.order-summary .order-summary-price dd.total-price {
	margin-top: 15px;
	padding-top: 15px;
}


.checkout-step-3,
.checkout-step-2.checkout-step-current,
.checkout-step-1.checkout-step-previous,
.checkout-step-2.checkout-step-previous {
	display: none;
}



.faq-section .accordion.theme-accordion .card {
	margin-top: 30px;
	overflow: hidden;
	border-bottom: 1px solid #dddddd;
	border-radius: 18px;
}

.faq-section .accordion.theme-accordion .card:first-child {
	margin-top: 0;
}

.faq-section .accordion.theme-accordion .card .card-header {
	padding: 0;
	border-bottom: 0;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	background-color: rgba(248, 248, 248, 0.65);
}

.faq-section .accordion.theme-accordion .card .card-header button {
	color: #333333;
	text-align: left;
	font-size: 16px;
	padding: 12px 20px !important;
	font-weight: 600;
	border: 0;
	line-height: 20px;
	display: block;
	width: 100%;
}

.faq-section .accordion.theme-accordion .card .card-header button:before {
	content: "";
	position: absolute;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-top: 6px solid #000000;
	right: 20px;
	top: 20px;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.faq-section .accordion.theme-accordion .card .card-header button[aria-expanded="true"]:before {
	border-top: 0;
	border-bottom: 6px solid #000000;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.faq-section .accordion.theme-accordion .card .card-header button:hover,
.faq-section .accordion.theme-accordion .card .card-header button:focus {
	text-decoration: none;
	color: #46bcff;
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	box-shadow: none !important;
}

.faq-section .accordion.theme-accordion .card .card-body {
	padding: 15px 18px;
}

.faq-section .accordion.theme-accordion .card .card-body p {
	margin-bottom: 0;
	font-size: 14px;
	color: #555555;
	line-height: 1.8;
	letter-spacing: 0.06em;
}

.popup_content {
	padding: 50px;
	text-align: center;
}

.heading_s1 {
	margin-bottom: 25px;
}

.heading_s1 h1,
.heading_s1 h2,
.heading_s1 h3,
.heading_s1 h4,
.heading_s1 h5,
.heading_s1 h6 {
	font-weight: 700;
	margin: 0;
	text-transform: none;
	line-height: 45px;
}

.faq-section h4 {
	font-size: 23px;
	margin-top: 40px;
	color: #49bdff;
}

.button__image-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.button__image-wrapper {
	display: block;
	width: 200px;
	height: 200px;
	overflow: hidden;
	border-radius: 100%;
	margin: auto;
}

.button-container.text-center.ml-2.mt-5.pt-5 {
	margin-top: 130px !important;
}

.button__label {
	font-weight: 600;
	margin-top: 10px !important;
	display: block;
}

.modal.modalmain {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: #fff;
	border: .75rem solid #46bcff;
	overflow-y: auto;
	z-index: 99;
	margin: auto;
	border-radius: 0;
}

.inner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 100%;
	overflow: auto;
}

.grid {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.heading-wrapper {
	margin-top: -3.5rem;
	position: relative;
	z-index: 1;
}

.modal.modalmain.show {
	opacity: 1 !important;
	visibility: visible;
}

.lead {
	color: #000;
	font-size: 24px;
	line-height: 39px;
}

.m-80 {
	max-width: 80%;
}

.modal-backdrop.show {

	display: none !important;
}

.background_bg.h-100 {
	background-size: 100%;
}

.modalmain .close,
.modalmain .close:hover {
	font-size: 30px;
	font-weight: 500;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 1;
	position: absolute;
	right: 20px;
	top: 10px !important;
}

.modalmain .close:not(:disabled):not(.disabled):focus,
.modalmain .close:not(:disabled):not(.disabled):hover {
	color: #000;
	text-decoration: none;
	opacity: 1;
}

.image-wrapper .image {
	width: 370px;
	height: 370px;
	object-fit: cover;
	border-radius: 57% 43% 46% 54%/59% 46% 54% 41%;
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	display: block;
}

.work-wrap {
	background: #46bcff;
	text-align: center;
	background-size: cover;
	padding: 60px 0;
	text-align: center;
}

.work-wrap ul {
	padding: 0;
	list-style-type: none;
}

.icon-holder {
	margin-bottom: 20px;
	border: 4px solid #fff;
	width: 150px;
	height: 150px;
	border-radius: 100%;
	display: inline-block;
	line-height: 150px;
}

.work-wrap p {
	font-size: 14px;
	line-height: 26px;
	margin-top: 25px;
	color: #fff;
}

.work-wrap h4 {
	font-size: 21px;
	line-height: 36px;
	color: #fff;
}

.subheadmain h3 {
	padding: 10px 20px;
	display: flex;
	background: #46bcff;
	width: max-content;
	border: 10px double #fff;
	border-radius: 20px;
	font-size: 20px;
	min-width: 200px;
	justify-content: center;
	color: #fff;
	margin-bottom: 30px;
	position: relative;
}

.subheadmain h3::after {
	border-top: 20px solid #46BCFF;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	content: "";
	display: inline-block;
	right: 0;
	position: absolute;
	bottom: -25px;
	left: 0;
	margin: auto;
	text-align: center;
	width: 20px;
}


.privacysection h3 {
	font-size: 30px;
	color: #49bdff;
	margin-top: 30px;
}

.privacysection ol li,
.privacysection ul li {
	margin-bottom: 20px;
}

.privacysection p {
	color: #000;
	font-weight: 300;
}

.privacysection h4 {
	font-size: 20px;
	color: #1f2732;
}





@media (max-width: 767px) {
	.sign-content h3 {
		font-size: 40px;
	}

	.wizard .nav-tabs>li a i {
		display: none;
	}

	.signup-logo-header .navbar-toggle {
		margin: 0;
		margin-top: 8px;
	}

	.signup-logo-header .logo_area {
		margin-top: 0;
	}

	.signup-logo-header .header-flex {
		display: block;
	}
}