.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hide-mobile {
  @media (max-width: 991px) {
    display: none;
  }
  
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
  .cookie-though {
    width: 90% !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ProximaNovaRegular {
  font-family: "ProximaNova-Regular";
}

.ProximaNovaBold {
  font-family: "Proxima-Nova-Bold";
}

.ProximaNovaSemiBold {
  font-family: "FontsFree-Net-Proxima-Nova-Sbold";
}


.cookie-though {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 500px;
  right: 0 !important;

  margin-left: auto;
  margin-right: 20px;
  /* The colors used to style the modal */
  --ct-primary-400: #46BCFF;
  --ct-primary-300: #EEEEEE;
  --ct-primary-200: #e0e0ff;
  --ct-primary-100: #f0f0ff;

  /* The colors used to style: customizationButton, headerDescription and cookiePolicy */
  --ct-text: black;

  /* The colors used to style the title elements */
  --ct-subtitle: var(--ct-primary-300);
  --ct-title: var(--ct-primary-400);

  /* The color used to style the cookie svg */
  --ct-cookie: var(--ct-primary-400);

  /* The colors used to style a policy */
  --ct-policy: var(--ct-white);
  --ct-policy-enabled: var(--ct-primary-400);
  --ct-policy-text: var(--ct-text);
  --ct-policy-text-enabled: var(--ct-white);
  --ct-policy-essential: var(--ct-primary-100);

  /* The colors used to style the sliders */
  --ct-slider-primary: #46BCFF;
  --ct-slider-secondary: #dfdcdc;
  --ct-slider-enabled-primary: #dfdcdc;
  --ct-slider-enabled-secondary: #fff;

  /* The font color of the text inside a policy when it's enabled  */
  --ct-enabled-option-color: var(--ct-white);

  /* The white color */
  --ct-white: #ffffff;

  /* The margin underneath text elements */
  --ct-text-margin: 0.25rem;

  /* The padding of the modal and margin of the banner, toggleButton and customization */
  --ct-default-padding: 1rem;
  --ct-default-margin: 100px;

  /* The padding/margin used to seperate the options and the permission buttons */
  --ct-seperator-padding: 0.5rem;

  /* The font size of the header title */
  --ct-heading-font-size: 1.25rem;

  /* The font size of the header sub title */
  --ct-sub-heading-font-size: 0.875rem;
  ;

  /* The font size of text */
  --ct-text-font-size: 0.75rem;

  /* The styling of the customize button and permission buttons */
  --ct-button-font-size: 0.8125rem;
  --ct-button-radius: 20px;
  --ct-button-border: 0;
  --ct-button-padding: 0.5rem;
  --ct-button: var(--ct-primary-400);
  --ct-button-color: var(--ct-white);
  --ct-button-secondary-border: 0;
  --ct-button-secondary-border-radius: "10px";
  --ct-button-secondary-color: var(--ct-text);

  /* The font and font weight used in the app */
  --ct-primary-font: 'ProximaNova-Regular';
  --ct-primary-font-weight: 400;

  /* The font and font weight used for header description and policy description */
  --ct-secondary-font: 'ProximaNova-Bold';
  --ct-secondary-font-weight: 400;

  /* The z-index you want the root container to have */
  --ct-z-index: 9999;
}

.cookie-though {
  animation: myfirst 3s linear;
}

@keyframes myfirst {
  0% {
    left: 0px;
    margin-bottom: -500px;
  }

}