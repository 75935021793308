.serviceAreaDropdown .dropdown button {
  background-image: url("../assets/images/Filter.svg");
  background-repeat: no-repeat;
  background-size: contain;
  color: #ffff !important;
  width: 80px;
  height: 80px;
  border-radius: 5px;
}


.serviceAreaDropdown .dropdown-toggle::after {
  border: 0;
}

.render-html-info-quill .ql-snow.ql-disabled {
  border: 0;
}

.render-html-info-quill .ql-toolbar {
  display: none;
}

.save-draft-card h4 {
  font-size: 20px;
  color: #040404;
  margin-bottom: 5px;
  line-height: 24px;
  text-align: left;
  font-weight: 600;
}


.beneficiary-filter .dropdown-menu{
  /* position: absolute; */
  inset: -20px auto auto -201px !important;
  /* margin: 0px;
  transform: translate3d(0px, 80px, 0px); */
}