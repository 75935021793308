.singleServices .dropdown button {
  background-image: url("../../assets/images/Filter.svg");
  background-repeat: no-repeat;
  background-size: contain;
  color: #ffff !important;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

/* .dropdown-toggle:empty::after {
    margin-left: 0;
    display: none;
} */

.singleServices .dropdown-toggle::after {
  border: 0;
}